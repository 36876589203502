<template>
  <div>
    <div class="tip">
      按不含税金额占比排序，前
      <BigPowerInput
        type="number"
        :min="1"
        v-model="filter.limit"
        size="mini"
        style="width: 80px; margin: 0 10px"
        @input="loadGXCategoryAnalysis"
      ></BigPowerInput>
      的购销对比如下：
    </div>
    <div v-loading="loading">
      <BigPowerTable :data="data" class="table"></BigPowerTable>
    </div>
    <PurchaseAndSaleTab :taxNo="taxNo" :limit="filter.limit"></PurchaseAndSaleTab>
  </div>
</template>

<script>
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { getGXCategoryAnalysis } from "@/api/threeYears";
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleTab from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleTab";

export default {
  components: {
    BigPowerTable,
    PurchaseAndSaleTab,
  },
  props: {
    taxNo: String,
    zq: [String, Number],
  },
  name: "PurchaseAndSaleCategoryCompare",
  async mounted() {
    this.loading = true;

    await this.loadGXCategoryAnalysis();
  },
  data() {
    return {
      data: null,
      loading: false,
      filter: {
        limit: 15,
      },
    };
  },
  methods: {
    async loadGXCategoryAnalysis() {
      const res = await getGXCategoryAnalysis({
        taxNo: this.taxNo,
        zq: this.zq,
        topCount: this.filter.limit,
        programId: this.$route.params.uuid,
      });
      this.data = res.data;
      this.loading = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.tip
  margin-top 20px
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
.table
  margin-top 21px
  //表格样式
.table {
  border-bottom:1px solid #e5e6eb
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
>>>.table .bigPowerTable {
  max-height: 552px;
}
>>> .el-input--mini .el-input__inner {
  height: 36px;
  width: 80px;
  line-height: 28px;
}
//只显示两行
>>>td .content[data-v-10dcf266]{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
</style>
