<template>
  <el-dialog title="风险企业详情" :visible.sync="dialogVisible" width="1100px">
    <div v-loading="!loaded" class="contentDialog">
      <div class="info">
        <div class="iconfontInfo"><i class="iconfont iconicon_qiye"></i></div>
        <div>
          <div class="companyName">{{ data.companyName }}</div>
          <div class="riskList">
            <!-- {{ data.riskList && data.riskList.join("、") }} -->
            <div v-for="item in data.riskList" :key="item" class="riskListItem">{{ item }}</div>
          </div>
        </div>
      </div>

      <div
        class="item"
        v-if="data.dataTableList"
        v-for="(table, index) in data.dataTableList"
        :key="index"
      >
        <div class="title">{{ table.tableTitle }}</div>
        <BigPowerTable :data="table" class="table"></BigPowerTable>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { getCompanyRiskInfo } from "@/api/threeYears";

export default {
  components: {
    BigPowerTable,
  },
  name: "CompanyRiskDialog",
  data() {
    return {
      dialogVisible: false,
      data: {},
      loaded: false,
    };
  },
  methods: {
    async open(taxNo) {
      this.data = {};
      this.loaded = false;
      this.dialogVisible = true;
      await this.loadCompanyRiskInfo(taxNo);
      this.loaded = true;
    },
    async loadCompanyRiskInfo(taxNo) {
      const res = await getCompanyRiskInfo({
        taxNo, //: '913301055714561592' //税号写死,记得替换一下
      });
      if (res.code == 200) {
        this.data = res.data;
      } else {
        this.data = {};
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .el-dialog__body
  padding 0px 0px 20px 20px

.info {
  width 99%
  padding 12px 16px
  //background: rgba(148, 191, 255, 0.1);
  border-radius: 2px;
  box-sizing border-box
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-bottom 14px;
  background: #F8FAFE;
  border-radius: 4px 4px 4px 4px;
  padding:20px;
  display: flex;
  .iconfontInfo{
    background: #6992f529;
    border-radius: 4px 4px 4px 4px;
    width: 68px;
    height: 68px;
    color:#2663F6;
    display: flex;
    align-items: center;
    justify-content: center;
    >>>.iconfont{
      font-size:34px;

    }
  }
  .companyName{
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #1D2129;
    line-height: 24px;
    margin-bottom: 16px;
    margin-left:20px;
  }
  .riskList{
    margin-left:20px;
    display: flex;
    .riskListItem{
     padding:5px 8px;
     background: #FEEBEB;
     color:#F53F3F;
     margin-right: 18px;
     border-radius: 2px 2px 2px 2px;
    }
  }
}

.item {
  margin-right: 12px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #4E5969;
    line-height: 20px;
    padding: 16px 0 12px;
  }

}

>>> .table.table {
  border 0 none
}

>>> .bigPowerTable {
  max-height 552px
}
//表格样式
.table {
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    //height: 204px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
>>>.table-wrapper .realTable-wrapper {
  border-top: 1px solid rgba(29,33,41,0.1);
  border-left: 1px solid rgba(29,33,41,0.1);
}
>>>.el-dialog__header {
  padding: 20px 20px 12px;
  border-bottom: 0 none;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
}
>>>td .content[data-v-10dcf266]{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
>>>.el-dialog__headerbtn .el-dialog__close {
  color: #999999;
  font-size: 18px;
  line-height: 18px;
}

// 滚动条的样式,宽高分别对应横竖滚动条的尺寸
.contentDialog::-webkit-scrollbar {
  width: 8px;
}

// 滚动条里面默认的小方块,自定义样式
.contentDialog::-webkit-scrollbar-thumb {
      background: #8D8F93;
      border-radius: 4px;
    }

// 滚动条里面的轨道
.contentDialog::-webkit-scrollbar-track {
      background: #fff;
}
.contentDialog::-webkit-scrollbar-thumb{
  width: 8px;
  background: #8D8F93;
  border-radius: 4px 4px 4px 4px;
}
.contentDialog{
  max-height: 618px;
  overflow-y: auto;
}
</style>
