<template>
  <div>
    <div class="main section" v-if="loaded">
      <div class="section-title">
        <div class="title">企业关联方</div>
        <BigPowerSwitcher v-model="showType"></BigPowerSwitcher>
      </div>
      <div class="time">
        <div>
          <div class="time">温馨提示:</div>
          <div>
            <ul>
              <li>
                1、系统当前版本只展示企业最新的关联方属性（“超实收资本50%的大额借贷方”属性除外）
              </li>
              <li>
                2、若在检测征期内当前企业的“股东持股以及对外投资”信息与当前相比均无变动，则系统可按年份展示“超实收资本50%的大额借贷方”的属性认定
              </li>
              <li>
                3、“超实收资本50%的大额借贷方”的属性认定需上传含有“客户（供应商）名称”的《科目余额表》，可前往<span
                  @click="clickTableSearch"
                  class="tableSearch"
                  >【报表查询】</span
                >页面进行补充后重新生成报告
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="filter-bodys" id="elinput">
        <div class="form-item">
          <div class="label">关联方属性：</div>
          <el-select
            v-model="filter.roleName"
            class="m-2"
            placeholder="请选择"
            @change="changeClick"
            clearable
          >
            <el-option
              v-for="item in getRelatedPartyProperty"
              :key="item.value"
              :label="item.value"
              :value="item"
              @keyup.enter.native="search"
            />
          </el-select>
        </div>
        <div class="form-item" v-if="statusShow">
          <div class="label">年份：</div>
          <el-select
            v-model="filter.status"
            class="m-2"
            placeholder="请选择"
            clearable
            @change="changeClickYear"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
              @keyup.enter.native="search"
            />
          </el-select>
        </div>
      </div>
      <div v-show="showType === 'table'">
        <div class="table-wrapper">
          <!-- 表格 -->
          <div class="table" style="margin: 16px 0">
            <template>
              <el-table :data="tableData" style="width: 100%" border max-height="500">
                <el-table-column prop="orderNumber" label="序号" />
                <el-table-column prop="relatedPartyValue" label="关联方属性">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.relatedPartyValue ? scope.row.relatedPartyValue : "————"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="relatedPartyName" label="关联方名称" />
                <el-table-column prop="relatedPartyBasis" label="关联方认证依据"
                  ><template slot-scope="scope">
                    <el-button
                      text
                      type="text"
                      @click="handleClick(scope.row.relatedPartyBasis)"
                      >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
      <div v-show="showType === 'chart'">
        <div>
          <relationPh ref="relationRef" :graphJsonData="graphJsonData"></relationPh>
        </div>
        <div v-if="isShowTableData">
          <div>
            <div class="tableTitle">一、关联方交易“进项指标”预警</div>
            <div class="table">
              <template>
                <el-table :data="ENTRYData" style="width: 100%" border max-height="500">
                  <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="relatedPartyName" label="关联方名称" />
                  <el-table-column prop="relatedPartyValue" label="关联方属性" />
                  <el-table-column prop="shareholdingRatio" label="'持股/投资'比例" />
                  <el-table-column prop="prompt" label="'预警/温馨'提示">
                    <template slot-scope="scope">
                      <span v-html="scope.row.prompt"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="processing" label="处理方式及法律依据">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.processing"
                        text
                        type="text"
                        @click="handleClick(scope.row.processing)"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div>
            <div class="tableTitle">二、关联方交易“销项指标”预警</div>
            <div class="table">
              <template>
                <el-table :data="SALESTable" style="width: 100%" border max-height="500">
                  <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="relatedPartyName" label="关联方名称" />
                  <el-table-column prop="relatedPartyValue" label="关联方属性" />
                  <el-table-column prop="shareholdingRatio" label="'持股/投资'比例" />
                  <el-table-column prop="prompt" label="'预警/温馨'提示">
                    <template slot-scope="scope">
                      <span v-html="scope.row.prompt"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="processing" label="处理方式及法律依据">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.processing"
                        text
                        type="text"
                        @click="handleClick(scope.row.processing)"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div>
            <div class="tableTitle">三、关联方交易"债资比指标"预警</div>
            <div class="table">
              <template>
                <el-table
                  :data="DEBTTable.debtProjectVOList"
                  style="width: 100%"
                  border
                  max-height="500"
                  :span-method="objectSpanMethod"
                >
                  <!-- <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="programName" label="项目名称" />
                  <el-table-column prop="amountRatio" label="金额/比例"
                    ><template slot-scope="scope">
                      <span>{{
                        scope.row.amountRatio.search("%") != -1
                          ? scope.row.amountRatio
                          : scope.row.amountRatio + "万元"
                      }}</span>
                    </template></el-table-column
                  >
                  <el-table-column prop="prompt" label="温馨提示">
                    <template slot-scope="scope">
                      <span v-html="scope.row.prompt"></span>
                    </template>
                  </el-table-column> -->
                  <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="programName" label="项目名称" />
                  <el-table-column prop="amountRatio" label="金额/比例"
                    ><template slot-scope="scope">
                      <span>{{
                        scope.row.amountRatio.search("%") != -1
                          ? scope.row.amountRatio
                          : scope.row.amountRatio + "万元"
                      }}</span>
                    </template></el-table-column
                  >
                  <el-table-column prop="prompt" label="温馨提示">
                    <template slot-scope="scope">
                      <span v-html="scope.row.prompt"></span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div>
            <div class="tableTitle" style="text-align: center">前五大关联方债权占比表</div>
            <div class="table">
              <template>
                <el-table :data="DEBTTable.top5VOList" style="width: 100%" border max-height="500">
                  <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="relatedPartyName" label="关联方名称" />
                  <el-table-column prop="shareholdingRatio" label="“持股/投资”比例" />
                  <el-table-column prop="subject" label="所在科目" />
                  <el-table-column prop="amount" label="年度各月平均关联债权投资之和"
                    ><template slot-scope="scope">
                      <span>{{ scope.row.amount + "万元" }}</span>
                    </template></el-table-column
                  >
                  <el-table-column prop="debtRadio" label="关联方债权占比"> </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div>
            <div class="tableTitle">四、关联方交易“其他指标”预警</div>
            <div class="table">
              <template>
                <el-table :data="OTHERTable" style="width: 100%" border max-height="500">
                  <el-table-column prop="orderNumber" label="序号" />
                  <el-table-column prop="relatedPartyName" label="关联方名称" />
                  <el-table-column prop="relatedPartyValue" label="关联方属性" />
                  <el-table-column prop="shareholdingRatio" label="'持股/投资'比例" />
                  <el-table-column prop="prompt" label="'预警/温馨'提示">
                    <template slot-scope="scope">
                      <span v-html="scope.row.prompt"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="processing" label="处理方式及法律依据">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.processing"
                        text
                        type="text"
                        @click="handleClick(scope.row.processing)"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div>
            <div class="tableTitle">五、关联方申报预警</div>
            <div class="table">
              {{ dataTable }}
            </div>
          </div>
        </div>
        <!-- <div class="tootip" v-else>
          <div>温馨提示：根据贵司提供的相关信息，经系统判定上述关联方均不存在交易行为</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import {
  getFinancialRisk,
  getRelatedPartyApi,
  getDEBTApi,
  getENTRYApi,
  getOTHERApi,
  getSALESApi,
  getWARNINGApi,
} from "@/api/threeYears";

import relationPh from "@/pages/Result/ThreeYearResult/complement/relation-graph";

export default {
  name: "enterpriseAffiliation",
  components: {
    BigPowerSwitcher,
    relationPh,
  },

  data() {
    return {
      showType: "chart",
      data: {},
      loaded: false,
      statusShow: false,
      filter: {
        roleName: "",
        status: null,
      },
      tableData: [],
      getRelatedPartyProperty: [],
      yearList: [],
      hasYEB: null,
      dataTable: "",
      ENTRYData: [],
      DEBTTable: {
        debtProjectVOList: [],
        top5VOList: [],
      },
      OTHERTable: [],
      SALESTable: [],
      //关联方属性
      relatedPartyCodeVale: "",
      yearData: "",
      graphJsonData: {},
      relatedPartyChartResult: {},
    };
  },

  async mounted() {
    this.loaded = false;
    await this.getRelatedParty();
    this.loaded = true;
    this.objectSpanMethod();
  },
  methods: {
    resetGraph() {
      this.$refs.relationRef.graphJsonData = this.relatedPartyChartResult;
      this.$refs.relationRef.showSeeksGraph();
    },
    search() {
      this.getRelatedParty();
    },
    getRelatedParty(e) {
      /* 关联方属性 */
      getRelatedPartyApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
        relatedYear: "",
      }).then((res) => {
        if (res.code == 200) {
          this.getRelatedPartyProperty = res.data.getRelatedPartyProperty;
          this.yearList = res.data.yearList;
          this.hasYEB = res.data.hasYEB;
          this.tableData = res.data.relatedPartyResultVOList;
          //
          this.relatedPartyChartResult = res.data.relatedPartyChartResult;
          this.resetGraph();
        }
      });
      // 企业关联方进项指标
      getENTRYApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
      }).then((res) => {
        if (res.code == 200) {
          this.ENTRYData = res.data;
        }
      });
      // 企业关联方销项指标
      getSALESApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
      }).then((res) => {
        if (res.code == 200) {
          this.SALESTable = res.data;
        }
      });
      // 企业关联方债资比指标
      getDEBTApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
      }).then((res) => {
        if (res.code == 200) {
          this.DEBTTable = res.data;
        }
      });
      // 企业关联方其他指标
      getOTHERApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
      }).then((res) => {
        if (res.code == 200) {
          this.OTHERTable = res.data;
        }
      });
      // 企业关联方申报预警
      getWARNINGApi({
        programId: this.$route.params.uuid,
      }).then((res) => {
        if (res.code == 200) {
          this.dataTable = res.data;
        }
      });
    },
    /* 详情 */
    handleClick(e) {
      this.$alert(e, "详情", {
        dangerouslyUseHTMLString: true,
      });
    },

    /* 属性切换 */
    changeClick(e) {
      this.relatedPartyCodeVale = e.code;
      this.getRelatedParty(e.code);
      // if (e == null) {
      if (e.code != 8) {
        this.statusShow = false;
      } else if (e.value == "债资比") {
        if (!this.yearList || this.hasYEB == false) {
          this.$confirm(
            "该属性认定需上传科目余额表，可前往【报表查询】页面补充后并重新生成报告即可查看!",
            "提示",
            {
              confirmButtonText: "确定",
              type: "warning",
            }
          );
        } else {
          getRelatedPartyApi({
            programId: this.$route.params.uuid,
            relatedPartyCode: e ? this.filter.roleName.code : "",
            relatedYear: filter.status ? filter.status : "",
          }).then((res) => {
            if (res.code == 200) {
              this.yearList = res.data.yearList;
              this.tableData = res.data.relatedPartyResultVOList;
              this.relatedPartyChartResult = res.data.relatedPartyChartResult;
              this.resetGraph();
            }
            // console.log(res);
          });
        }
      } else {
        this.statusShow = true;
      }
    },
    /* 年份切换 */
    changeClickYear(e) {
      this.yearData = e;
      getRelatedPartyApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: e ? this.filter.roleName.code : "",
        relatedYear: e ? e : "",
      }).then((res) => {
        if (res.code == 200) {
          // this.yearList = res.data.yearList;
          this.tableData = res.data.relatedPartyResultVOList;
          this.relatedPartyChartResult = res.data.relatedPartyChartResult;
          this.resetGraph();
        }
      });
      this.getRelatedParty();
    },
    /* 去报表查询 */
    clickTableSearch() {
      this.goTableSearch = true;
      this.$emit("toTable");
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 3) {
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 1,
          };
        }
      }
    },
  },
  computed: {
    isShowTableData() {
      let arrLength =
        this.ENTRYData.length +
        this.SALESTable.length +
        this.DEBTTable.debtProjectVOList.length +
        this.DEBTTable.top5VOList.length +
        this.OTHERTable.length;
      return arrLength;
    },
  },
  watch: {
    showType(oldType, newType) {
      if (newType == "chart") return;
      getRelatedPartyApi({
        programId: this.$route.params.uuid,
        relatedPartyCode: this.filter.roleName ? this.filter.roleName.code : "",
        relatedYear: "",
      }).then((res) => {
        if (res.code == 200) {
          this.relatedPartyChartResult = res.data.relatedPartyChartResult;
          this.resetGraph();
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.section-title
  height: 22px;
  color: #1D2129;
  line-height: 22px;
  display flex
  justify-content space-between
  border-bottom 1px solid #F2F3F5;
  padding-bottom 10px

  .title
    font-size: 16px;
    font-weight: 600;

.ciyun-wrapper
  .toolbar
    display flex
    justify-content flex-end

.time {
  //margin 20px 16px
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  list-style: none
  li{
    list-style: none
  }
  ul{
    margin-left:-40px;
  }
  .tableSearch{
    text-decoration: underline
    color:blue
    cursor:pointer
  }
}

.table-wrapper
  background: #FFFFFF;
  margin 20px 0
  margin-bottom 0

  .table {
    >>> .bigPowerTable {
      max-height 720px
      overflow auto
    }

  }

  //新增

.tootip{
  div{
    background: #dedede;
    height:48px;
    width:100%;
    line-height: 48px;
    margin:38px 0;
  }
}
.tableTitle{
  margin:16px 0;
}
>>>.el-table th {
  background-color: #f5f7fa;
}

.filter-bodys {
    display: flex;
    align-items: center;

    .form-item {
      margin-right: 40px;
      margin-bottom: 16px;
      display: flex;
      align-items: center

      .label {
        white-space: nowrap;
        text-align: right;
        margin-right: 8px;
        display: flex;
      }
    }
  }
.section {
    margin: 20px auto;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
}
</style>
