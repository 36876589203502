import {ext} from '@antv/matrix-util';
import * as G6 from "@antv/g6";

const transform = ext.transform;

G6.registerNode('companyRisk', {

  drawShape: (cfg, group) => {
//白色背景
  group.addShape("rect", {
    attrs: {
        y: -45,
        fill: "#fff",
        width: 240,
        height:  cfg.risk.length!=0?94:65,
        radius: [4, 4],
        // cursor: 'pointer',
    }})
    //top蓝色背景
   group.addShape("rect", { 
    attrs: {
        width: 240,
        height: 26,
        x:0,
        y: -45,
        fill: '#165dff24',
        radius: [4, 4,0,0]
    }
});
   //总价
   group.addShape("text", { 
    attrs: {
        text: cfg.totalAmout || "",
        x: 15,
        y: -30,
        fill: '#2663F6',
        textAlign: "left",
        textBaseline: "middle" ,
        fontSize: 18,
        fontWeight: 600,
    }
});
   //单位
   group.addShape("text", { 
    attrs: {
        text: cfg.totalAmout? " 万元":'',
        x:cfg.totalAmout.length==0
        ?25:cfg.totalAmout.length==1
        ?35:cfg.totalAmout.length==2
        ?45:cfg.totalAmout.length==3
        ?55:cfg.totalAmout.length==4
        ?65:cfg.totalAmout.length==5
        ?75:cfg.totalAmout.length==6
        ?80:cfg.totalAmout.length==7
        ?90:cfg.totalAmout.length==8
        ?100:cfg.totalAmout.length==9
        ?110:cfg.totalAmout.length==10
        ?120:cfg.totalAmout.length==11
        ?130:140,
        y: -30,
        fill: '#1D2129',
        textAlign: "left",
        textBaseline: "middle" ,
    }
});
   //红色背景
   group.addShape("rect", { 
    attrs: {
        x: 0,
        y: 15,
        height: cfg.risk.length!=0?24:0,
        width:240,
        fill:'#FEEBEB',
        textAlign: "left",
        textBaseline: "middle" ,
    }
});
   //红色字1
   group.addShape("text", { 
    attrs: {
        // text: cfg.risk[0]&&cfg.risk[1]&&cfg.risk[2]?(cfg.risk[0]+' '+cfg.risk[1]+' '+cfg.risk[2]+' '+'...'):
        // cfg.risk[0]&&cfg.risk[1]?(cfg.risk[0]+' '+cfg.risk[1]):
        // cfg.risk[0]?cfg.risk[0]:'',
        text:cfg.risk[0]&&cfg.risk[1]?(cfg.risk[0]+'  '+cfg.risk[1]+'  '+' ...'):cfg.risk[0]?cfg.risk[0]:' ',

        //  text: cfg.risk[0]?cfg.risk[0]:'',
        x: 15,
        y: 27,
        fill:'#F53F3F',
        textAlign: "left",
        textBaseline: "middle" ,
        fontSize: 12,
    }
   });
      //红色字2
      // group.addShape("text", { 
      //   attrs: {
      //       text: cfg.risk[1]?cfg.risk[1]:'',
      //       x:cfg.risk[0]?.length==5?80:70,
      //       y: 27,
      //       fill:'#F53F3F',
      //       textAlign: "left",
      //       textBaseline: "middle" ,
      //       fontSize: 12,
      //   }
      //  });
             //红色字3
      // group.addShape("text", { 
      //   attrs: {
      //       text: cfg.risk[2]?cfg.risk[2]:'',
      //       x:cfg.risk[1]?.length==5?140:140,
      //       y: 27,
      //       fill:'#F53F3F',
      //       textAlign: "left",
      //       textBaseline: "middle" ,
      //       fontSize: 12,
      //   }
      //  });
                    //红色字3
      // group.addShape("text", { 
      //   attrs: {
      //       text: cfg.risk[3]?'...':'',
      //       x:cfg.risk[2]?180:10,
      //       y: 27,
      //       fill:'#F53F3F',
      //       textAlign: "left",
      //       textBaseline: "middle" ,
      //       fontSize: 12,
      //   }
      //  });
    //节点黑色部分
    const rect = group.addShape('text', {
      attrs: {
        text: cfg.companyName.length>14? cfg.companyName.slice(0,14)+'...':cfg.companyName,
        x: 15,
        y: 0,
        fontSize: 14,
        textAlign: cfg.textAlign || 'left',
        textBaseline: 'middle',
        fill: '#1D2129',
        fontWeight: 600,
      },
      name: 'company-risk-shape',
      draggable: true,
    });
    return rect
  },
})
