<template>
  <div class="rela">
    <div style="height: calc(100vh - 80px)">
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
        :on-line-click="onLineClick"
        :on-node-expand="onNodeExpand"
        :on-node-collapse="onNodeCollapse"
      />
    </div>
  </div>
</template>

<script>
import RelationGraph from "relation-graph";
import { getRelatedPartyGraph } from "@/api/api";

export default {
  name: "Demo",
  props: {
    // graphJsonData: [Object] 
  },
  components: { RelationGraph },
  data() {
    return {
      graphOptions: {
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: "border",
        defaultNodeColor: "#2f54eb",
        defaultNodeBorderColor: "#2f54eb",
        defaultLineWidth: 2,
 "defaultNodeShape": 1,
        // 这里可以参考"Graph 图谱"中的参数进行设置
        __graph_json_data: {},
        graphJsonData: {}
      },
    };
  },
  mounted() {
    // this.showSeeksGraph();
    // this.getRelatedPartyGra();
  },
  methods: {
    // getRelatedPartyGra() {
    //   let params = {
    //     programId: this.$route.params.uuid,
    //     relatedPartyCode: this.name == "全部" ? "" : this.name.code,
    //     relatedYear: this.year || "",
    //   };
    //   getRelatedPartyGraph(params).then((res) => {
    //     this.__graph_json_data = res.data.relatedPartyChartResult;
    //     this.showSeeksGraph();
    //   });
    // },
    showSeeksGraph() {
      // console.log(this.graphJsonData)
      const __graph_json_data = this.graphJsonData;
      // 以上数据中的node和link可以参考"Node节点"和"Link关系"中的参数进行配置
      this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (graphInstance) => {
        // Called when the relation-graph is completed
        // 这些写上当图谱初始化完成后需要执行的代码
        // 获取根节点的子节点，即可获得图谱第一层中的节点
        const level_1_nodes = graphInstance.getNodeById(__graph_json_data.rootId).lot
          .childs;
        level_1_nodes.forEach((thisLevel1Node) => {
          this.applyCollapseStyle2Node(thisLevel1Node);
        });
        this.$refs.seeksRelationGraph.refresh();
      });
    },
    applyCollapseStyle2Node(_node) {
      // _node的子节点将被隐藏，同时让_node右侧显示一个加号，点击后可以展开子节点
      if (_node.lot.childs.length > 0) {
        _node.lot.childs.forEach((thisChildNode) => {
          thisChildNode.isShow = false;
          this.applyCollapseStyle2Node(thisChildNode);
        });
        _node.expanded = false;
        _node.expandHolderPosition = "right";
      }
    },
    onNodeCollapse(node, e) {
      // console.log("onNodeCollapse:", node);
      // 当有一些节点被显示或隐藏起来时，会让图谱看着很难看，需要布局器重新为节点分配位置，所以这里需要调用refresh方法来重新布局
      // this.$refs.seeksRelationGraph.refresh();
    },
    onNodeExpand(node, e) {
      // 当有一些节点被显示或隐藏起来时，会让图谱看着很难看，需要布局器重新为节点分配位置，所以这里需要调用refresh方法来重新布局
      // console.log("onNodeExpand:", node);
      // this.$refs.seeksRelationGraph.refresh();
    },
    onNodeClick(nodeObject, $event) {
      // console.log("onNodeClick:", nodeObject);
    },
    onLineClick(lineObject, $event) {
      // console.log("onLineClick:", lineObject);
    },
  },
};
</script>

<style lang="stylus">
.rela .rel-node-shape-1 .c-node-text {
  padding-left: 0;
}
</style>
