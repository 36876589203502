<template>
  <div class="main section" v-if="loaded">
    <div class="section-menu-bar">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-bar"
        mode="horizontal"
        @select="handleTopMenuSelect"
      >
        <el-menu-item index="1">关键税负率</el-menu-item>
        <el-menu-item index="2">关键财务比率</el-menu-item>
      </el-menu>
    </div>
    <div>
      <KeyTaxRate
        v-if="keyFinancialData"
        :data="keyFinancialData.data[activeIndex - 1]"
        :key="activeIndex"
        class="KeyTaxRate"
      ></KeyTaxRate>
    </div>
  </div>
  <div class="main section" v-else>
    <BigPowerLoading></BigPowerLoading>
  </div>
</template>

<script>
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import "@/pages/Result/ThreeYearResult/global.styl";
import KeyTaxRate from "@/pages/Result/ThreeYearResult/financialAnalysis/KeyTaxRate";
import { getKeyFinancialRatios } from "@/api/threeYears";

export default {
  components: {
    KeyTaxRate,
  },
  name: "KeyFinanceRatio",
  async mounted() {
    this.loaded = false;
    await this.loadKeyFinancialRatios();
    this.loaded = true;
  },
  data() {
    return {
      activeIndex: "1",
      keyFinancialData: null,
      loaded: false,
    };
  },
  methods: {
    async loadKeyFinancialRatios() {
      const res = await getKeyFinancialRatios({
        programId: this.$route.params.uuid,
      });
      this.keyFinancialData = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>


.KeyTaxRate
  margin-top 24px
>>> .section-menu-bar .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 4px solid #165dff;
  border-radius: 2px 2px 2px 2px;
  color:#2663F6;
}
>>> .section-menu-bar .el-menu--horizontal .el-menu-item {
  padding: 0px 0px;
  font-size: 18px;
  color: #1D2129;
  margin: 0px;
  margin-right: 40px;
  height: 42px;
  line-height: 25px;
  font-weight: bold;
}
.section{
  width: 1100px;
  //height: 628px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box
}
</style>
