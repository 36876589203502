<template>
  <div ref="theChart" class="echart"></div>
</template>
<script>
import echarts from "echarts";
import smoothNumber from "@/utils/smooth";
import { loginChangeToken } from "@/api/api";

export default {
  name: "RadarChart",
  props: {
    data: Array,
  },
  data() {
    return {
      colors: ["#2663F6"],
    };
  },
  computed: {
    indicator() {
      return this.data.map((v) => {
        return {
          text: v.name,
          value: v.value,
          max: 100,
        };
      });
    },
    values() {
      return this.data.map((v) => {
        return v.value;
      });
    },
  },
  mounted() {
    const myChart = echarts.init(this.$refs.theChart);
    const dataItem = this.data.map((v) => {
      return {
        text: v.name,
        value: v.value,
      };
    });
    const options = {
      tooltip: {
        trigger: "axis",
        // formatter: "{b}<br/>{a}:{c}",
        formatter: function (params) {
          let relVal = params.name;
          for (let i = 0; i < dataItem.length; i++) {
            relVal += "<br/>" + dataItem[i].text + " : " + dataItem[i].value;
          }
          return relVal;
        },
      },
      legend: {
        show: false,
        left: "center",
        data: ["财务分析"],
        color: "#fff",
      },
      radar: [
        {
          indicator: this.indicator,
          center: ["48%", "50%"],
          radius: 150,
          nameGap: 14, // 指示器名称和指示器轴的距离。[ default: 15 ]
          name: {
            formatter: function (text, raw) {
              const { value } = raw;
              return `{a|${value}}` + "\n" + "\n" + text;
            },
            rich: {
              a: {
                align: "center",
                fontWeight: "bold",
                color: "#FFB100",
                fontSize: 14,
              },
            },
            textStyle: {
              //全局字体样式
              color: "#15181f9c",
              fontSize: "14px",
            },
          },
        },
      ],
      series: [
        {
          type: "radar",
          tooltip: {
            trigger: "item",
          },
          areaStyle: {},
          data: [
            {
              value: this.values,
              name: "财务分析(分)",
              areaStyle: {
                color: "#207DFB",
                opacity: 0.2,
              },
              itemStyle: {
                normal: {
                  borderWidth: 2,
                  color: "#207DFB",
                },
              },
              lineStyle: {
                type: "solid",
                color: "#207DFB",
                width: 2,
              },
              symbol: "circle",
              symbolSize: 10,
            },
          ],
        },
      ],
    };

    myChart.setOption(options);

    myChart.on("click", (params) => {
      var city = params.name;
      if (city) {
        this.$emit("click", city);
      } else {
        this.$emit("click", null);
      }
      //this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid, this.type, this.$route, {area: city.replace("①", "").replace("②", "").replace("③", "")}));
    });
  },
};
</script>
<style lang="stylus" scoped>
.echart {
  width 100%
  height 500px
}
</style>
