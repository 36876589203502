<template>
  <div ref="mountNode" class="mountNode" :style="{ height: height }"></div>
</template>

<script>
import * as G6 from "@antv/g6";
import "./CompanyRisk";

export default {
  name: "NetworkChart",
  props: {
    data: [Object],
    height: {
      type: String,
      default: "720px",
    },
  },
  data() {
    return {
      graph: null,
    };
  },
  mounted() {
    // const minimap = new G6.Minimap({
    //   size: [100, 100],
    //   className: 'minimap',
    //   type: 'delegate',
    // });
    // 创建 G6 图实例
    if (this.graph) {
      this.graph.destory();
    }

    this.graph = new G6.Graph({
      // plugins: [tooltip],
      container: this.$refs["mountNode"],
      defaultNode: {
        type: "rect",
      },
      //线条设置
      defaultEdge: {
        type: "cubic-horizontal",
      },
      fitCenter: true,
      // 画布宽高
      width: this.$refs["mountNode"].offsetWidth,
      height: this.$refs["mountNode"].offsetHeight,
      // animate:true,
      layout: {
        // Object，可选，布局的方法及其配置项，默认为 random 布局。
        // type: "force", // 指定为力导向布局
        // preventOverlap: true, // 防止节点重叠
        // nodeSize: 130, // 节点大小，用于算法中防止节点重叠时的碰撞检测。由于已经在上一节的元素配置中设置了每个节点的 size 属性，则不需要在此设置 nodeSize。
        // linkDistance: 100,
        // getWidth: () => {
        //   return 200;
        // },
        // maxZoom: 1, // 展开时的放缩比例
        // center: [500, 300],
      },
      modes: {
        // 支持的 behavior
        default: ["drag-canvas", "zoom-canvas"],
        //edit: ['click-select'],
      },
    });
  },
  watch: {
    data() {
      this.render();
    },
    deep: true,
  },
  methods: {
    render() {
      if (this.data == null) {
        return;
      }

      const rectHeight = 110;
      const yBegin = 360 - (this.data.topTenSupplierList.length / 2) * rectHeight;
      //topTenSupplierList左边
      const leftNodes = this.data.topTenSupplierList.map((v, index) => {
        return {
          id: `node${index}_left`,
          x: -60,
          y: yBegin + rectHeight * index,
          type: "companyRisk",
          style: {
            fill: "#FEEBEB",
            width: 240,
            height: 26,
          },
          totalAmout: v.totalAmout,
          companyName: v.name,
          // risk: v.riskList.join("   "),
          risk: v.riskList,
          textAlign: "left",
        };
      });

      const leftEdges = this.data.topTenSupplierList.map((v, index) => {
        return {
          source: `node${index}_left`,
          target: "nodeSelf",
          // label: v.totalAmout + "万元",
          labelCfg: {
            position: "left",
            autoRotate: true,
            refX: 10,
          },
          style: {
            endArrow: true,
            startArrow: false,
          },
        };
      });
      // console.log(leftEdges);
      //topTenCustomerList右边
      const rightNodes = this.data.topTenCustomerList.map((v, index) => {
        return {
          id: `node${index}_right`,
          x: 1000,
          y: yBegin + rectHeight * index,
          type: "companyRisk",
          totalAmout: v.totalAmout,
          companyName: v.name,
          // risk: v.riskList.join("   "),
          risk: v.riskList,
          textAlign: "left",
          style: {
            lineWidth: 0,
          },
        };
      });

      const rightEdges = this.data.topTenCustomerList.map((v, index) => {
        return {
          source: `node${index}_right`,
          target: "nodeSelf",
          // label: v.totalAmout + "万元",
          labelCfg: {
            position: "start",
            autoRotate: true,
            refX: 10,
          },
          style: {
            endArrow: false,
            startArrow: true,
          },
        };
      });

      const centerNode = {
        id: "nodeSelf",
        x: 600,
        y: 360,
        type: "rect",
        label: this.data.companyName,
        //边框色
        stroke: "#2663F6",
        style: {
          width: 180,
          fill: "#2663F6",
          fontSize: 16,
          radius: 4,
          fontWeight: 600,
        },
        labelCfg: {
          // 标签文字配置属性
          positions: "center", // 标签文字在元素中的位置
          style: {
            // 标签文字样式
            fill: "#ffffff", // ...
            fontWeight: 600,
          },
        },
      };
      // todo 设置鼠标悬停显示详情操作
      // const tooltip = new G6.Tooltip({
      //   offsetX: 70,
      //   offsetY: 20,
      //   getContent(e) {
      //     const outDiv = document.createElement("div");
      //     outDiv.style.width = "180px";
      //     outDiv.innerHTML = `
      //       <ul id="nodeDetails">
      //         <li>名称: ${e.item.getModel().label}</li>
      //         <li>IP: ${e.item.getModel().ip}</li>
      //         <li>ID: ${e.item.getModel().id}</li>
      //         <li>status: ${e.item.getModel().status}</li>
      //       </ul>`;

      //     return outDiv;
      //   },
      // });
      // 定义数据源
      const data = {
        // 点集
        nodes: [centerNode, ...leftNodes, ...rightNodes],
        // 边集
        edges: [...leftEdges, ...rightEdges],
      };
      // 读取数据
      this.graph.data(data);
      // 渲染图
      this.graph.render();
      //設置大小
      // this.graph.changeSize(1100, 500);
      if (leftEdges.length != 0 && rightEdges.length != 0) {
        this.graph.fitView(-200);
      } else {
        this.graph.zoomTo(1);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.mountNode
  height: 726px;
  background: #F6F9FE;
  border-radius: 4px 4px 4px 4px;
</style>
