<template>
  <div class="wrapper" :style="{ height: height }">
    <div class="chart-wrapper" :style="{ height: height }">
      <div class="wrapperTitle">
        <div class="total">{{ data.total }}</div>
        <div class="wrapperName" v-if="this.data.length != 0">
          {{ data.invoiceImageMap.ruleOfInvoiceImageList[0].imageTitle }}
        </div>
      </div>

      <PieChart :colors="colors" :data="chartData" :key="JSON.stringify(chartData)"></PieChart>
      <span class="unit">
        <!-- {{ "单位:" + data.invoiceImageMap.ruleOfInvoiceImageList[0].unit }} -->
        {{ "单位:" + chartData[0].unit }}
      </span>
    </div>
    <div
      class="legends"
      :style="{ height: height }"
      v-if="this.chartData && this.chartData != undefined"
    >
      <div class="legend" v-for="(item, index) in chartData" :key="index">
        <div class="title">
          <div class="box" :style="{ background: colors[index % colors.length] }"></div>
          {{ item.name }}
        </div>
        <div class="value">
          <span> {{ item.value }}</span
          ><span v-if="item.value == 0.0 || item.value == 0 || item.value == '0'">
            {{ "(" + "0" + "%" + ")" }}
          </span>
          <span v-else>{{ "(" + ((item.value / data.total) * 100).toFixed(2) + "%" + ")" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./costExpensesPieChartTwo";
export default {
  name: "costExpensesPieTwo",
  props: {
    data: null,
    height: {
      type: String,
      default: "362px",
    },
  },
  components: {
    PieChart,
  },
  computed: {
    chartData() {
      const ret = [];
      if (this.data.total) {
        for (const i in this.data.invoiceImageMap.ruleOfInvoiceImageList[0]?.xLabels) {
          const label = this.data.invoiceImageMap.ruleOfInvoiceImageList[0]?.xLabels[i];
          const value = this.data.invoiceImageMap.ruleOfInvoiceImageList[0]?.series[0].data[i];
          const units = this.data.invoiceImageMap.ruleOfInvoiceImageList[0]?.unit;
          ret.push({ name: label, value, unit: units });
        }
        return ret;
      }
    },
  },
  data() {
    return {
      colors: [
        "#6492FF",
        "#FDBF33",
        "#71DDA4",
        "#FF8F64",
        "#9D6CED",
        "#E264E2",
        "#A4D02B",
        "#1799D3",
        "#7E91FF",
        "#EAB57C",
        "#6569C5",
        "#30CADE",
        "#C99696",
        "#8DD87F",
        "#3AA5B2",
        "#B78122",
      ],
    };
  },
  mounted() {
    // console.log(this.chartData);
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  //width: 828px;
  height: 362px;

  .chart-wrapper {
    height: 362px;
    display: flex;
    align-items: center;
    margin: 0px 120px;
  }

  .legends {
    height: 362px;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    margin-right: 193px;

    .legend {
      width: 100%;
      color: #666;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin: 11px 0;

      .box {
        width: 24px;
        height: 16px;
        background: #6492FF;
        border-radius: 4px 4px 4px 4px;
        display: inline-block;
        background: red;
        position: relative;
        top: 3px;
        margin-right: 6px;
      }

      .title {
        vertical-align: top;
        width: 197px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #1D2129;
      }

      .value {
        vertical-align: top;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #1D2129;
        margin-left: 14px;
      }
    }
  }
}
.wrapperTitle{
  position: absolute;
  display: flex
  flex-direction: column;
  align-items: center;
  left: 221px;
  width: 137px;
  .total{
    font-size: 24px;
    font-family: DIN Alternate-Bold, DIN Alternate;
    font-weight: bold;
    color: #2663F6;
    line-height: 37px;
  }
  .wrapperName{
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D2129;
  }
}
.unit{
  position: relative;
  right: -567px;
  top: -262px;
  width: 73px;
  display: flex;
  justify-content: center
}
</style>
<style lang="scss">
.box-items {
  width: auto !important;
  padding: 0.8rem 0.5rem;
}
</style>
