<template>
  <div>
    <div class="main section" v-if="loaded">
      <div class="section-title">
        <div class="titleIcon"></div>
        <div class="title">历史纳税情况</div>
      </div>
      <!-- <div class="section-menu-bar">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-bar"
        mode="horizontal"
        @select="handleTopMenuSelect"
      >
        <el-menu-item index="1">历史纳税情况</el-menu-item>
      </el-menu>
    </div> -->
      <div>
        <div class="time">{{ data.data[0].name }}</div>
        <div class="stats-wrapper">
          <div class="stats">
            <div class="stats-item" v-for="item in bodyT.data" :key="item.index">
              <div style="position: absolute">
                <img class="backImg" src="../../../../../assets/util/img_shuiyin.png" alt="" />
              </div>
              <div class="card">
                <div class="year">{{ item.year }}年</div>
                <div style="display: flex; justify-content: space-around">
                  <div class="creditRating">
                    <div class="numS">{{ item.creditLevel }}</div>
                    <div class="cName">信用等级</div>
                  </div>
                  <div class="grade">
                    <div class="numS">{{ item.creditPoint }}</div>
                    <div class="cName">信用评分</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="time" style="margin-bottom: 14px">{{ data.data[1].name }}</div>
        <div
          v-if="!bodyL.data || bodyL.data == [] || bodyL.data == ''"
          class="IllegalInformation"
          style="min-height: 280px"
        >
          <img class="backImgNull" src="../../../../../assets/util/无数据插画.png" alt="" />
          <div class="Illegal">无税收违法信息</div>
        </div>
        <div class="IllegalInformation" style="padding: 16px 20px" v-else>
          <div class="stats">
            <div class="stats-item" v-for="item in bodyL.data" :key="item.id">
              <div class="statsYear">{{ item.publishTime }}</div>
              <div class="caseType">案件性质: {{ item.caseType }}</div>
              <div class="caseInfo">
                主要违法事实、法律依据及处理处罚情况：
                {{ item.caseInfo }}
              </div>
              <div class="statsLine"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeIndex == 1">
        <div class="table-wrapper">
          <TaxCreditLevel :data="data.data[0]" style="width: 360px"></TaxCreditLevel>
          <TaxCreditLevel :data="data.data[1]" style="width: 780px"></TaxCreditLevel>
        </div>
      </div>
    </div>
    <div class="bottomIcon"></div>
  </div>
</template>

<script>
import TaxCreditLevel from "@/pages/Result/ThreeYearResult/financialAnalysis/historyTaxCondition/TaxCreditLevel";
import { getTaxPayment } from "@/api/threeYears";

export default {
  name: "HistoryTaxCondition",
  components: {
    TaxCreditLevel,
  },
  async mounted() {
    this.loaded = false;
    await this.loadTaxPayment();
    this.loaded = true;
  },
  data() {
    return {
      activeIndex: "2",
      loaded: false,
      data: null,
      bodyT: null,
      bodyL: null,
    };
  },
  methods: {
    async loadTaxPayment() {
      const res = await getTaxPayment({ programId: this.$route.params.uuid });
      this.data = res.data;
      this.bodyT = res.data.data[0];
      this.bodyL = res.data.data[1];
      // console.log(res.data);
      // console.log(this.bodyT);
      // console.log(this.bodyL);
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>
.table-wrapper
  display flex
  justify-content space-between

  >>> .bigPowerTable {
    max-height 480px
    overflow auto
  }
.section{
  width: 1100px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box;
  margin-bottom: 0px;
}
.time{
  font-weight: bold;
  color: #1D2129;
  margin-top: 20px
}
.titleIcon
    width: 4px;
    height: 20px;
    background: #2663F6;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-right: 20px
.section-title
  height: 22px;
  color: #2663F6;
  line-height: 20px;
  display flex
  border-bottom 1px solid #e6e6e6;
  padding-bottom 18px
  .title
    font-size: 18px;
    font-weight: 600;
.stats-wrapper
  margin 20px 0;

  .stats
    display flex
    //justify-content: space-between
    margin-top 24px
    margin-bottom: 40px;

    .stats-item
      //width: 300px;
      width: 28.3%;
      height: 139px;
      margin-right:80px;
      display flex;
      //align-items center
      //justify-content center
      //flex-direction column
      background: linear-gradient(134deg, #1A9EFF 0%, #2663F6 100%);
      border-radius: 16px 16px 16px 16px;
      padding: 12px 20px;
      box-sizing: border-box;

      .number
        font-size: 30px;
        font-weight: 600;
        color: #1D2129;
        line-height: 42px;
      .card
        display: flex
        flex-direction column
        width:100%
        .year
          width: 68px;
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        .creditRating
          display: flex
          flex-direction column
          align-items: center
        .grade
          display: flex
          flex-direction column
          align-items: center
        .numS
          font-size: 30px;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 57px;
          padding-top: 2px
        .cName
          ont-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;

    .stats-item:last-child{
      margin-right: 0px
    }

.backImg{
  position relative
  right: -165px;
  top: -12px
}
.IllegalInformation{
  background: #F1F6FE;
  border-radius: 4px 4px 4px 4px;
  .backImgNull{
    margin: 60px 460px 0;
  }
  .Illegal{
    margin: 0 481px 60px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D2129;
    line-height: 22px;
  }
  .stats{
    .statsYear{
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #1D2129;
    }
    .caseType,.caseInfo{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D2129;
      line-height: 22px;
      margin-top:10px;
    }
    .statsLine{
      border-bottom:2px dashed #e6e6e6;
      margin: 10px 0;
    }
  }
}
.bottomIcon{
  height:20px
  background: #F6F8FA
}
</style>
