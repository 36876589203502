<template>
  <div class="bill-content">
    <div class="bill-detail-content">
      <div class="bill-detail-menu">
        <div class="getDataDeleteTextTip">{{ getDataDeleteTextTip }}</div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">进项发票</el-menu-item>
          <el-menu-item index="2">销项发票</el-menu-item>
        </el-menu>
        <el-tooltip
          class="item"
          effect="dark"
          content="系统采集发票数据时，优先采集专用发票，然后采集普通发票。在发票量大的月份，可能存在普通发票不全的情况。"
          placement="right-end"
        >
          <div class="bill-detail-info">发票情况说明</div>
        </el-tooltip>
        <div class="bill-detail-time">
          统计区间: {{ this.rangeTimes.start }} 至 {{ this.rangeTimes.end }}
        </div>
      </div>
      <div class="bill-time-filter">
        <div>选择日期：</div>
        <div>
          <el-date-picker
            v-model="billTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="search" @click="timeChange">查询</div>
        <el-divider direction="vertical"></el-divider>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 0 }"
          @click="nowMonth"
        >
          本月
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 1 }"
          @click="lastMonth"
        >
          上月
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 2 }"
          @click="nowYear"
        >
          本年
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 3 }"
          @click="lastYear"
        >
          上年
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 4 }"
          @click="allResult"
        >
          全部
        </div>
        <div class="bill-select-type">
          <div>发票类型：</div>
          <div class="good-type">
            <el-select
              v-model="goodtype"
              placeholder="请选择"
              clearable
              @change="searchItem(1)"
              :disabled="getTable"
            >
              <el-option
                v-for="item in billtypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="billStatus">发票状态：</div>
          <div class="good-type">
            <el-select
              v-model="goodStatus"
              placeholder="请选择"
              clearable
              :disabled="getTable"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in billStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-icon-searchs" @click="searchItem(1)">
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
      <div class="bill-time-table">
        <div class="left">
          <div class="title">
            <div class="title-company">公司名称：</div>
            <div class="title-input">
              <el-input
                clearable
                v-model="nameFilter"
                placeholder="请输入内容"
                @change="namefilterMeth"
                @input="namefilterclear"
              ></el-input>
            </div>
            <div
              class="risk-type"
              :class="{ 'risk-type-active': radioTypeCopy == 1 }"
              @click="riskTypeVisible = true"
            >
              风险类型
            </div>
          </div>
          <div v-loading="getTableCompany">
            <div class="content-c" v-if="billData && billData.length > 0" id="contents">
              <i class="el-icon-top el-icon-tops" @click="toTop"></i>
              <div class="contents" v-for="item in billData">
                <div
                  class="content-item"
                  :class="{ contentAct: contentIndex == item.taxNo }"
                  @click="item2Act(item)"
                >
                  <div class="company price">
                    {{ item.companyName }}
                    <span v-for="item in item.riskList">{{ item }}</span>
                  </div>
                  <div class="info">
                    <div class="price">
                      金额：{{ item.totalAmount }}元
                      <span class="copy-price" @click.stop="copyText(item.totalAmount)"
                        >复制</span
                      >
                    </div>
                    <div class="price">
                      税额：{{ item.totalTax }}元<span
                        class="copy-price"
                        @click.stop="copyText(item.totalTax)"
                        >复制</span
                      >
                    </div>
                    <div class="price">
                      价税合计：{{ item.amountTax }}元<span
                        class="copy-price"
                        @click.stop="copyText(item.amountTax)"
                        >复制</span
                      >
                    </div>
                  </div>
                  <i
                    @click="contentRight = !contentRight"
                    v-if="!contentRight"
                    class="el-icon-caret-right content-right"
                  ></i>
                  <i
                    @click="contentRight = !contentRight"
                    v-if="contentRight"
                    class="el-icon-caret-bottom content-right"
                  ></i>
                </div>
                <template>
                  <div
                    v-show="contentRight"
                    v-for="item2 in item.child"
                    class="content-item2"
                    @click="item2Act(item2)"
                    :class="{ contentAct: contentIndex == item2.taxNo }"
                  >
                    <div class="company price">
                      {{ item2.companyName }}
                      <span v-for="item3 in item2.riskList" class="item3-company">{{
                        item3
                      }}</span>
                    </div>
                    <div class="info">
                      <div class="price">
                        金额：{{ item2.totalAmount }}元<span
                          class="copy-price"
                          @click.stop="copyText(item2.totalAmount)"
                          >复制</span
                        >
                      </div>
                      <div class="price">
                        税额：{{ item2.totalTax }}元<span
                          class="copy-price"
                          @click.stop="copyText(item2.totalTax)"
                          >复制</span
                        >
                      </div>
                      <div class="price">
                        价税合计：{{ item2.amountTax }}元<span
                          class="copy-price"
                          @click.stop="copyText(item2.amountTax)"
                          >复制</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-show="getMore" class="get-moredata" @click="getMoreData">
                加载更多
              </div>
            </div>
            <div v-else class="content-flex">
              <div>暂无数据</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-item">
            <div class="item" @click="changeItem">
              <div class="taxname" :class="{ 'bill-name': item == 0 }" data-index="0">
                商品明细
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 1 }" data-index="1">
                品类统计
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 2 }" data-index="2">
                发票信息
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 3 }" data-index="3">
                {{ activeIndex == 1 ? "每月进项" : "每月销项" }}
              </div>
            </div>
            <div class="item-goods">
              <div class="select-group" v-show="item != 2">
                <div>货品和劳务名称：</div>
                <div>
                  <el-input
                    clearable
                    v-model="goodsFilter"
                    placeholder="请输入内容点击查询按钮"
                    @change="goodsFilterMeth"
                    @input="goodsFilterMeth"
                  ></el-input>
                </div>
                <el-button type="primary" @click="newSearchItem">查询</el-button>
                <div class="more-class" @click="moreClass">分类</div>
              </div>
              <div class="more-filter" @click="moreGoods">
                {{ moreGoodsIndex ? "收起更多" : "更多检索" }}
              </div>
            </div>
          </div>
          <div class="select-group">
            <div class="item select" v-if="item == 1">
              <div class="chart-img">
                <img
                  @click="itemSelect = 1"
                  src="@/assets/util/chart-none.png"
                  alt=""
                  v-if="itemSelect == 0"
                />
                <img
                  src="@/assets/util/chart-highlight.png"
                  alt=""
                  v-if="itemSelect == 1"
                />
              </div>
              <div class="chart-img chart-img-right">
                <img
                  @click="itemSelect = 0"
                  src="@/assets/util/table-none.png"
                  alt=""
                  v-if="itemSelect == 1"
                />
                <img
                  src="@/assets/util/table-highlight.png"
                  alt=""
                  v-if="itemSelect == 0"
                />
              </div>
            </div>
            <div class="right-item-filter" v-show="moreGoodsIndex">
              <div class="radio radio-top">
                <div class="rate">税率:</div>
                <div @click="clearGroupFilterRate" class="clear-filter">不限</div>
                <el-checkbox-group v-model="taxRateFilter" @change="newSearchItem">
                  <el-checkbox :label="0">0</el-checkbox>
                  <el-checkbox :label="0.01">1%</el-checkbox>
                  <el-checkbox :label="0.03">3%</el-checkbox>
                  <el-checkbox :label="0.05">5%</el-checkbox>
                  <el-checkbox :label="0.06">6%</el-checkbox>
                  <el-checkbox :label="0.09">9%</el-checkbox>
                  <el-checkbox :label="0.1">10%</el-checkbox>
                  <el-checkbox :label="0.11">11%</el-checkbox>
                  <el-checkbox :label="0.13">13%</el-checkbox>
                  <el-checkbox :label="0.16">16%</el-checkbox>
                  <el-checkbox :label="0.17">17%</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="radio">
                <div class="rate">异常发票:</div>
                <div
                  @click="clearGroup1Filter"
                  class="clear-filter"
                  v-show="activeIndex == 1"
                >
                  不限
                </div>
                <el-checkbox-group
                  v-model="abnormalFilter1"
                  v-show="activeIndex == 1"
                  @change="newSearchItem"
                >
                  <el-checkbox :label="0">税局代开</el-checkbox>
                  <el-checkbox :label="1">备注栏不准确</el-checkbox>
                  <el-checkbox :label="2">名称或税号不符</el-checkbox>
                  <el-checkbox :label="3">疑似顶额开票</el-checkbox>
                  <el-checkbox :label="4">外地劳务发票</el-checkbox>
                  <!-- <span class="el-icon-relative"
                    ><el-checkbox :label="5">开票人年龄异常</el-checkbox
                    ><el-tooltip
                      class="item"
                      effect="dark"
                      content="开票人年龄大于70或者小于16"
                      placement="top-start"
                    >
                      <i class="el-icon-warning-outline el-icon"></i> </el-tooltip
                  ></span> -->
                </el-checkbox-group>
                <div
                  @click="clearGroup2Filter"
                  class="clear-filter"
                  v-show="activeIndex == 2"
                >
                  不限
                </div>
                <el-checkbox-group
                  v-model="abnormalFilter2"
                  v-show="activeIndex == 2"
                  @change="newSearchItem"
                >
                  <el-checkbox :label="0">备注栏不准确</el-checkbox>
                  <el-checkbox :label="1">名称或税号不符</el-checkbox>
                  <el-checkbox :label="2">疑似顶额开票</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="item-table" v-show="item == 0">
            <el-table
              :data="billTableDataItem0"
              :max-height="totalNumber0 > 200 ? tableHeight - 20 : tableHeight"
              style="width: 100%"
              v-loading="getTable"
              :header-cell-style="{ background: '#F7F8FA', 'text-align': 'center' }"
              border
            >
              <!-- <el-table-column width="20" fixed> </el-table-column> -->
              <el-table-column
                width="270"
                prop="productName"
                label="货品和劳务名称"
                fixed
              >
              </el-table-column>
              <!-- <el-table-column width="150" prop="productModel" label="规格型号">
              </el-table-column> -->
              <!-- <el-table-column width="80" prop="productUnit" label="单位" align="center">
              </el-table-column> -->
              <!-- <el-table-column
                width="120"
                prop="productNumber"
                label="数量"
                sortable
                :sort-method="sortproductNumber"
                align="right"
              >
              </el-table-column> -->
              <!-- <el-table-column
                width="160"
                prop="unitPriceExcludingTax"
                label="不含税单价（元）"
                sortable
                :sort-method="sortunitPriceExcludingTax"
                align="right"
              >
              </el-table-column> -->
              <el-table-column
                width="160"
                prop="amountExcludingTax"
                label="金额（元）"
                sortable
                :sort-method="sortamountExcludingTax"
                align="right"
              >
              </el-table-column>
              <el-table-column
                width="90"
                prop="taxRateDetailed"
                label="税率"
                sortable
                :sort-method="sorttaxRateDetailed"
                align="right"
              >
              </el-table-column>
              <el-table-column
                width="120"
                prop="taxDetailed"
                label="税额（元）"
                sortable
                :sort-method="sorttaxDetailed"
                align="right"
              >
              </el-table-column>
              <el-table-column
                width="150"
                prop="taxAmount"
                label="价税合计（元）"
                sortable
                :sort-method="sorttaxtaxAmount"
                align="right"
              >
              </el-table-column>
              <el-table-column prop="invoiceType" label="发票种类" width="230">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.invoiceType == "-"
                      ? "-"
                      : billTypeArray[scope.row.invoiceType]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="invoiceStatus"
                label="发票状态"
                width="130"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="activeIndex == 1">{{
                    scope.row.invoiceStatus == "-"
                      ? "-"
                      : billStatusListArray[scope.row.invoiceStatus]
                  }}</span>
                  <span v-if="activeIndex == 2">{{
                    scope.row.invoiceStatus == "-"
                      ? "-"
                      : billStatusListArraySale[scope.row.invoiceStatus]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!relationTaxNo"
                width="250"
                prop="relationName"
                :label="activeIndexList[activeIndex - 1]"
                sortable
              >
              </el-table-column>
              <el-table-column
                width="120"
                prop="billingDate"
                label="开票日期"
                sortable
                align="center"
              >
              </el-table-column>
              <el-table-column
                width="140"
                prop="invoiceCode"
                label="发票代码"
                align="center"
              >
              </el-table-column>
              <el-table-column
                width="140"
                prop="invoiceNumber"
                label="发票号码"
                align="center"
              >
              </el-table-column>
              <el-table-column width="320" align="left" prop="remark" label="备注">
                <template slot-scope="scope">
                  <el-tooltip
                    :open-delay="300"
                    :content="scope.row.remark"
                    placement="top"
                  >
                    <div class="remark-ellipsis">
                      {{ scope.row.remark }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="item-pagination" v-show="totalNumber0 > 200">
              <el-pagination
                @size-change="handleSizeChange0"
                @current-change="handleCurrentChange0"
                :current-page="pageNum0"
                :page-size="pageSize0"
                layout="prev, pager, next, total, jumper, sizes"
                :page-sizes="[100, 200, 300, 400, 500]"
                :total="totalNumber0"
              >
              </el-pagination>
            </div>
          </div>
          <div class="item-table" v-show="item == 1">
            <div v-show="itemSelect == 0">
              <el-table
                :data="billTableDataItem1"
                style="width: 100%"
                :max-height="tableHeight"
                v-loading="getTable"
                :header-cell-style="{ background: '#F7F8FA', 'text-align': 'center' }"
                border
              >
                <!-- <el-table-column width="20" fixed> </el-table-column> -->
                <el-table-column
                  prop="productName"
                  label="货品和劳务名称"
                  width="280"
                  fixed
                >
                </el-table-column>
                <!-- <el-table-column
                  prop="productUnit"
                  label="单位"
                  width="110"
                  align="center"
                >
                </el-table-column> -->
                <!-- <el-table-column
                  prop="productNumber"
                  label="数量"
                  width="150"
                  sortable
                  :sort-method="sortproductNumber1"
                  align="right"
                >
                </el-table-column> -->
                <el-table-column
                  prop="amountExcludingTax"
                  label="合计金额(万元)"
                  width="150"
                  sortable
                  :sort-method="sortamountExcludingTax1"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  prop="taxDetailed"
                  label="合计税额(万元)"
                  width="150"
                  sortable
                  :sort-method="sorttaxDetailed1"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  prop="taxAmount"
                  label="价税合计(万元)"
                  width="150"
                  sortable
                  :sort-method="sorttaxAmount1"
                  align="right"
                >
                </el-table-column>
                <!-- <el-table-column
                  prop="averagePrice"
                  label="均价（元）"
                  width="180"
                  sortable
                  :sort-method="sortaveragePrice1"
                  align="right"
                >
                </el-table-column> -->
                <el-table-column
                  prop="percent"
                  label="占总金额比例"
                  width="160"
                  sortable
                  :sort-method="sortpercent1"
                  align="right"
                >
                  <template slot-scope="scope">
                    <span>{{ (scope.row.percent * 100).toFixed(2) }}%</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="item-pagination" v-show="totalNumberpl > 200">
                <el-pagination
                  @size-change="handleSizeChangepl"
                  @current-change="handleCurrentChangepl"
                  :current-page="pageNumpl"
                  :page-size="pageSizepl"
                  layout="prev, pager, next, total, jumper, sizes"
                  :page-sizes="[100, 200, 300, 400, 500]"
                  :total="totalNumberpl"
                >
                </el-pagination>
              </div>
            </div>
            <div class="circle-flex" v-show="itemSelect == 1">
              <div class="circle" id="circle"></div>
              <div class="circle-item">
                <div v-for="(item, index) in billCircleData" class="bill-Data">
                  <div
                    class="circle-item-color"
                    :style="{ background: backgroundColor2[index] }"
                  ></div>
                  <div class="bill-Data-content">
                    {{ item.name }}
                  </div>
                  <div>{{ (item.value * 100).toFixed(2) }}%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-table" v-show="item == 2">
            <el-table
              :data="billTableDataItem2"
              :max-height="totalNumber1 > 200 ? tableHeight - 40 : tableHeight"
              style="width: 100%"
              v-loading="getTable"
              :header-cell-style="{ background: '#F7F8FA', 'text-align': 'center' }"
              border
            >
              <!-- <el-table-column width="20" fixed> </el-table-column> -->
              <el-table-column
                prop="billingDate"
                label="开票日期"
                width="110"
                sortable
                fixed
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceCode"
                label="发票代码"
                width="130"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceNumber"
                label="发票号码"
                width="130"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="invoiceType" label="发票种类" width="230">
                <template slot-scope="scope">
                  <span>{{ billTypeArray[scope.row.invoiceType] }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="invoiceStatus"
                label="发票状态"
                width="130"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="activeIndex == 1">{{
                    billStatusListArray[scope.row.invoiceStatus]
                  }}</span>
                  <span v-if="activeIndex == 2">{{
                    billStatusListArraySale[scope.row.invoiceStatus]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="金额（元）"
                width="150"
                sortable
                :sort-method="sorttotalAmount"
                align="right"
              >
              </el-table-column>
              <el-table-column
                prop="totalTax"
                label="税额（元）"
                width="150"
                sortable
                :sort-method="sorttotalTax"
                align="right"
              >
              </el-table-column>
              <el-table-column
                prop="amountTax"
                label="价税合计（元）"
                width="150"
                sortable
                :sort-method="sortamountTax"
                align="right"
              >
              </el-table-column>
              <el-table-column
                prop="consolidateRate"
                label="税率"
                width="130"
                align="right"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注" width="250" align="right">
              </el-table-column>
              <template v-if="relationTaxNo != contentIndex">
                <el-table-column
                  prop="companyName"
                  label="销售方名称"
                  width="360"
                  v-if="activeIndex == 1"
                >
                </el-table-column>
                <el-table-column
                  prop="companyName"
                  label="采购方名称"
                  width="230"
                  v-else
                  align="right"
                >
                </el-table-column>
              </template>
            </el-table>
            <div class="item-pagination" v-show="totalNumber1 > 200">
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="pageNum1"
                :page-size="pageSize1"
                layout="prev, pager, next, total, jumper, sizes"
                :page-sizes="[100, 200, 300, 400, 500]"
                :total="totalNumber1"
              >
              </el-pagination>
            </div>
          </div>
          <div class="item-table" v-show="item == 3">
            <el-table
              :data="billTableDataItem3"
              style="width: 100%"
              :max-height="tableHeight"
              v-loading="getTable"
              :header-cell-style="{ background: '#F7F8FA', 'text-align': 'center' }"
              border
            >
              <!-- <el-table-column width="20"> </el-table-column> -->
              <el-table-column prop="yearMonth" label="年度月份" sortable align="center">
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="合计金额（元）"
                sortable
                :sort-method="sorttotalAmount3"
                align="right"
              >
              </el-table-column>
              <el-table-column
                prop="totalTax"
                label="合计税额（元）"
                sortable
                :sort-method="sorttotalTax3"
                align="right"
              >
              </el-table-column>
              <el-table-column
                prop="amountTax"
                label="价税合计（元）"
                sortable
                :sort-method="sortamountTax3"
                align="right"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="风险类型"
      :visible.sync="riskTypeVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div class="block">
        <span class="demonstration">选择风险类型: </span>
        <span
          ><el-radio-group v-model="radioType">
            <el-radio :label="1">全部企业</el-radio>
            <el-radio :label="2">无风险企业</el-radio>
            <el-radio :label="3">有风险企业</el-radio>
          </el-radio-group></span
        >
        <div class="el-cascader-c" v-show="radioType == 3">
          <el-cascader
            collapse-tags
            v-model="optionsValue"
            @change="optionsChange"
            :options="options"
            :props="props"
            clearable
          ></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="riskTypeVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="riskTypeVisibleComfirm">确 定</el-button>
      </span>
    </el-dialog>
    <classification
      :ClassData0="ClassSearchList0"
      :ClassData1="ClassSearchList1"
      ref="block"
      @classChange="classChange"
      :classValueIndex="activeIndex"
    ></classification>
  </div>
</template>

<script>
import {
  getCustomListNew,
  getEntryCompanyList,
  getSalesCompanyList,
  getEntryInvoiceGoodsList,
  getSalesInvoiceGoodsList,
  getEntryInvoiceCategoryList,
  getSalesInvoiceCategoryList,
  getEntryInvoiceList,
  getSalesInvoiceList,
  getEntryInvoiceMonthList,
  getSalesInvoiceMonthList,
  getInvoiceSection,
  getEntryClass,
  getSalesClass,
  getDataDeleteText2
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import circleBill from "./circleBill";
import classification from "./classification";

import echarts from "echarts";

const moment = require("moment");

import autoTableHeight from "@/pages/Result/ThreeYearResult/bill/utils/help.js";

import clearAllChildren from "./clearAllChildren.js";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    circleBill,
    classification,
  },

  computed: {
    tableHeight() {
      return autoTableHeight(); // 2. 调用函数，返回高度
    },
  },

  props: {
    timeText: [String]
  },

  data() {
    return {
      getMore: true,
      billPage: 1,
      ClassSearchList: [],
      abnormalFilter1: [],
      abnormalFilter2: [],
      taxRateFilter: [],
      moreGoodsIndex: false,
      goodsFilter: "",
      radioTypeCopy: 1,
      getTableCompany: false,
      radioType: 1,
      optionsValueList: [],
      optionsValue: [
        // [1],
        ["司法风险", "法律诉讼"],
        ["司法风险", "被执行人"],
        ["司法风险", "失信人"],
        ["司法风险", "严重违法"],
        ["司法风险", "行政处罚"],
        ["司法风险", "限制消费令"],
        ["税务风险", "税收违法"],
        ["税务风险", "欠税"],
        ["税务风险", "纳税信用等级低"],
        ["经营风险", "经营状态异常"],
        ["经营风险", "经营异常"],
        ["经营风险", "社保人数异常"],
        ["经营风险", "成立不满6个月"],
        ["经营风险", "个人独资企业"],
        ["经营风险", "个体工商户"],
      ],
      props: { multiple: true, expandTrigger: "hover" },
      options: [
        // {
        //   value: 1,
        //   label: "无风险企业",
        // },
        // {
        // value: 2,
        // label: "有风险企业",
        // children: [
        {
          value: "司法风险",
          label: "司法风险",
          children: [
            { value: "法律诉讼", label: "法律诉讼" },
            { value: "被执行人", label: "被执行人" },
            { value: "失信人", label: "失信人" },
            { value: "严重违法", label: "严重违法" },
            { value: "行政处罚", label: "行政处罚" },
            { value: "限制消费令", label: "限制消费令" },
          ],
        },
        {
          value: "税务风险",
          label: "税务风险",
          children: [
            { value: "税收违法", label: "税收违法" },
            { value: "欠税", label: "欠税" },
            { value: "纳税信用等级低", label: "纳税信用等级低" },
          ],
        },
        {
          value: "经营风险",
          label: "经营风险",
          children: [
            { value: "经营状态异常", label: "经营状态异常" },
            { value: "经营异常", label: "经营异常" },
            { value: "社保人数异常", label: "社保人数异常" },
            { value: "成立不满6个月", label: "成立不满6个月" },
            { value: "个人独资企业", label: "个人独资企业" },
            { value: "个体工商户", label: "个体工商户" },
          ],
        },
        // ],
        // },
      ],
      riskTypeVisible: false,
      activeIndexList: ["销售方名称", "采购方名称" ],
      backgroundColor2: [
        "#EC5757",
        "#BCA88D",
        "#2588ED",
        "#F7A659",
        "#95A5CD",
        "#FF409A",
        "#B8D4F9",
        "#ABDE8A",
        "#EED9BE",
        "#6293F8",
        "#878787",
      ],
      MaxHeightTable: "100vh",
      getTable: false,
      billTypeArray: {
        "01": "增值税专用发票  专用发票",
        "02": "货物运输业增值税专用发票  专用发票",
        "03": "机动车销售统一发票  其他发票",
        "04": "增值税普通发票  普通发票",
        "08": "电子专用发票  专用发票",
        10: "电子普通发票  普通发票",
        11: "卷式普通发票  普通发票",
        14: "电子普通[通行费]发票  普通发票",
        15: "二手车统一发票  其他发票",
        "004": "增值税专用发票  专用发票",
        "007": "增值税普通发票  普通发票",
        "009": "货物运输业增值税专用发票  专用发票",
        "005": "机动车销售统一发票  其他发票",
        "006": "二手车统一发票  其他发票",
        "026": "电子普通发票  普通发票",
        99: "通用机打发票  普通发票",
        c: "增值税普通发票  普通发票",
        s: "增值税专用发票  专用发票",
        j: "机动车销售统一发票  其他发票",
        p: "电子普通发票  普通发票",
        51: "全电发票 (铁路电子客票)",
        61: "全电发票(航空运输电子客票行程单)",
        81: "全电发票(增值税专用发票)",
        82: "全电发票(普通发票)",
        85: "全电纸质发票(增值税专用发票)",
        86: "全电纸质发票(普通发票)",
      },
      relationTaxNo: "", //点击左侧公司才有这个税号
      companyNameTitle: this.$route.query.companyName,
      createTimeTitle: this.$route.query.createTime,
      contentRight: true,
      billtype: "",
      billStatus: "",
      goodtype: "",
      goodStatus: [],
      itemSelect: 0,
      contentIndex: 0,
      item: 0,
      activeIndex: "1",
      billTime: "",
      nameFilter: "",
      billtypeList: [
        {
          value: "1",
          label: "专用发票",
        },
        {
          value: "2",
          label: "普通发票",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
      billData: [],
      billStatusListArray: ["正常", "作废", "红冲", "失控", "异常"],
      billStatusListArraySale: ["正常", "红冲", "空白发票", "作废", "异常", "失控"],
      billStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "作废",
        },
        {
          value: "2",
          label: "红冲",
        },
        {
          value: "3",
          label: "失控",
        },
        {
          value: "4",
          label: "异常",
        },
      ],
      billTableDataItem0: [],
      billTableDataItem1: [],
      billTableDataItem2: [],
      billTableDataItem3: [],
      pageNum0: 1,
      pageSize0: 200,
      totalNumber0: 10,
      pageNum1: 1,
      pageSize1: 200,
      totalNumber1: 10,
      billCircleData: [],
      isActive: 4,
      rangeTimes: {
        start: "",
        end: "",
      },
      productNoList: [[], []],
      ClassSearchList0: [],
      ClassSearchList1: [],
      // 品类统计
      pageNumpl: 1,
      pageSizepl: 200,
      totalNumberpl: 10,
      getDataDeleteTextTip: ""
    };
  },

  activated() {
    this.contentIndex = this.$route.query.taxNo;
    this.optionsChange();
    // this.searchCompany();
    this.searchItem();
    this.getDataRange();
    //
    this.entryClassSearch();
    this.salesClassSearch();
    this.getgetDataDeleteText();
  },

  methods: {
    getgetDataDeleteText() {
      getDataDeleteText2(this.$route.query.uuid).then((res) => {
        this.getDataDeleteTextTip = res.data.text;
        // this.$emit("setTime");
      });
    },
    //
    handleSizeChangepl(val) {
      this.pageSizepl = val;
      this.pageNumpl = 1;
      this.searchItemChange();
    },
    handleCurrentChangepl(val) {
      this.pageNumpl = val;
      this.searchItemChange();
    },
    //
    toTop() {
      document.getElementById("contents").scrollTo(0, 0);
    },
    getMoreData() {
      this.billPage++;
      this.searchCompany();
    },
    entryClassSearch() {
      getEntryClass(this.$route.query.taxNo).then((res) => {
        let data = res.data;
        this.ClassSearchList0 = clearAllChildren(data);
      });
    },
    salesClassSearch() {
      getSalesClass(this.$route.query.taxNo).then((res) => {
        let data = res.data;
        this.ClassSearchList1 = clearAllChildren(data);
      });
    },
    classChange(val) {
      this.productNoList[this.activeIndex - 1] = val;
      this.newSearchItem();
    },
    moreClass() {
      this.$refs.block.dialogVisible = true;
    },
    clearGroupFilterRate() {
      this.taxRateFilter = [];
      this.newSearchItem();
    },
    clearGroup1Filter() {
      this.abnormalFilter1 = [];
      this.newSearchItem();
    },
    clearGroup2Filter() {
      this.abnormalFilter2 = [];
      this.newSearchItem();
    },
    moreGoods() {
      this.moreGoodsIndex = !this.moreGoodsIndex;
    },
    goodsFilterMeth() {},
    optionsChange() {
      let optionsValue = [];
      for (let i = 0; i < this.optionsValue.length; i++) {
        if (this.optionsValue[i].length == 2) {
          optionsValue.push(this.optionsValue[i][1]);
        }
      }
      this.optionsValueList = optionsValue;
    },
    riskTypeVisibleFalse() {
      this.riskTypeVisible = false;
    },
    riskTypeVisibleComfirm() {
      this.billPage = 1;
      this.radioTypeCopy = this.radioType;
      this.searchCompany();
      this.riskTypeVisible = false;
    },
    handleClose() {
      this.riskTypeVisible = false;
    },
    copyText(val) {
      var copyTest = val;
      var inputTest = document.createElement("input");
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
    getCurrentData() {
      this.contentIndex = this.$route.query.taxNo;
      this.getEntryCompany();
      this.searchItem();
      this.getDataRange();
    },
    getDataRange() {
      getInvoiceSection(this.$route.query.uuid).then((res) => {
        if (!res.data) return;
        this.rangeTimesFor = res.data.endDate;
        this.rangeTimes.start = res.data.startDate
          ? moment(res.data.startDate).format("YYYY-MM-DD")
          : "";
        this.rangeTimes.end = res.data.endDate
          ? moment(res.data.endDate).format("YYYY-MM-DD")
          : "";
        //
        if (!res.data.startDate) return;
        this.billTime = [];
        [this.billTime[0], this.billTime[1]] = [
          moment(this.rangeTimes.start).format("YYYY-MM-DD"),
          moment(this.rangeTimes.end).format("YYYY-MM-DD"),
        ];
        //
        this.$emit("deleteBillType", res.data.deleted);
      });
    },
    handleSizeChange0(val) {
      this.pageSize0 = val;
      this.pageNum0 = 1;
      this.searchItemChange();
    },
    handleCurrentChange0(val) {
      this.pageNum0 = val;
      this.searchItemChange();
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.pageNum1 = 1;
      this.searchItemChange();
    },
    handleCurrentChange1(val) {
      this.pageNum1 = val;
      this.searchItemChange();
    },
    sorttaxtaxAmount(obj1, obj2) {
      let val1 = obj1.taxAmount;
      let val2 = obj2.taxAmount;
      return val1 - val2;
    },
    sortproductNumber1(obj1, obj2) {
      let val1 = obj1.productNumber;
      let val2 = obj2.productNumber;
      return val1 - val2;
    },
    sortamountExcludingTax1(obj1, obj2) {
      let val1 = obj1.amountExcludingTax;
      let val2 = obj2.amountExcludingTax;
      return val1 - val2;
    },
    sorttaxAmount1(obj1, obj2) {
      let val1 = obj1.taxAmount;
      let val2 = obj2.taxAmount;
      return val1 - val2;
    },
    sorttaxDetailed1(obj1, obj2) {
      let val1 = obj1.taxDetailed;
      let val2 = obj2.taxDetailed;
      return val1 - val2;
    },
    sortaveragePrice1(obj1, obj2) {
      let val1 = obj1.averagePrice;
      let val2 = obj2.averagePrice;
      return val1 - val2;
    },
    sortpercent1(obj1, obj2) {
      let val1 = obj1.percent;
      let val2 = obj2.percent;
      return val1 - val2;
    },
    sortunitPriceExcludingTax(obj1, obj2) {
      let val1 = obj1.unitPriceExcludingTax;
      let val2 = obj2.unitPriceExcludingTax;
      return val1 - val2;
    },
    sortproductNumber(obj1, obj2) {
      let val1 = obj1.productNumber;
      let val2 = obj2.productNumber;
      return val1 - val2;
    },
    sortamountExcludingTax(obj1, obj2) {
      let val1 = obj1.amountExcludingTax;
      let val2 = obj2.amountExcludingTax;
      return val1 - val2;
    },
    sorttaxRateDetailed(obj1, obj2) {
      let val1 = parseInt(obj1.taxRateDetailed);
      let val2 = parseInt(obj2.taxRateDetailed);
      return val1 - val2;
    },
    sorttaxDetailed(obj1, obj2) {
      let val1 = obj1.taxDetailed;
      let val2 = obj2.taxDetailed;
      return val1 - val2;
    },
    sorttotalAmount(obj1, obj2) {
      let val1 = obj1.totalAmount;
      let val2 = obj2.totalAmount;
      return val1 - val2;
    },
    sorttotalTax(obj1, obj2) {
      let val1 = obj1.totalTax;
      let val2 = obj2.totalTax;
      return val1 - val2;
    },
    sortamountTax(obj1, obj2) {
      let val1 = obj1.amountTax;
      let val2 = obj2.amountTax;
      return val1 - val2;
    },
    sorttotalAmount3(obj1, obj2) {
      let val1 = obj1.totalAmount;
      let val2 = obj2.totalAmount;
      return val1 - val2;
    },
    sorttotalTax3(obj1, obj2) {
      let val1 = obj1.totalTax;
      let val2 = obj2.totalTax;
      return val1 - val2;
    },
    sortamountTax3(obj1, obj2) {
      let val1 = obj1.amountTax;
      let val2 = obj2.amountTax;
      return val1 - val2;
    },
    // 时间筛选框
    timeChange() {
      // console.log(this.billTime);
      this.billPage = 1;
      this.searchSetIndex();
      // this.searchCompany();
      this.searchItem();
    },
    // 公司名称过滤器
    namefilterMeth() {
      this.billPage = 1;
      this.searchCompany();
    },
    // 公司名称清空
    namefilterclear() {
      this.billPage = 1;
      if (!this.nameFilter) {
        this.searchCompany();
      }
    },
    item2Act(item) {
      this.contentIndex = item.taxNo;
      // 如果点击了母公司，关联企业参数为空
      if (this.contentIndex == this.$route.query.taxNo) {
        this.relationTaxNo = "";
      } else {
        this.relationTaxNo = this.contentIndex;
      }
      this.searchSetIndex();
      this.searchItem();
    },
    getEntryCompany() {
      let params = {
        endDate: this.billTime ? this.billTime[1] : "",
        startDate: this.billTime ? this.billTime[0] : "",
        taxNo: this.$route.query.taxNo,
        companyName: this.nameFilter,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        companyRiskStatus: this.radioType,
        riskList: this.radioType == 3 ? this.optionsValueList : [],
        pageNum: this.billPage,
        pageSize: 200,
        desensitizationStatus: this.$route.query.desensitization,
      };
      getEntryCompanyList(params).then((res) => {
        if (res.data.child.length < 200) {
          this.getMore = false;
        }
        this.getTableCompany = false;
        if (res.data.taxNo) {
          if (this.billPage == 1) {
            this.billData = [];
            this.billData.push(res.data);
          } else {
            // console.log(res.data.child);
            this.billData[0].child = this.billData[0].child.concat(res.data.child);
            // console.log(this.billData);
          }
        } else {
          this.billData = [];
        }
      });
    },
    getSalesCompany() {
      let params = {
        endDate: this.billTime ? this.billTime[1] : "",
        startDate: this.billTime ? this.billTime[0] : "",
        taxNo: this.$route.query.taxNo,
        companyName: this.nameFilter,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        companyRiskStatus: this.radioType,
        riskList: this.radioType == 3 ? this.optionsValueList : [],
        pageNum: this.billPage,
        pageSize: 200,
        desensitizationStatus: this.$route.query.desensitization,
      };
      getSalesCompanyList(params).then((res) => {
        if (res.data.child.length < 200) {
          this.getMore = false;
        }
        this.getTableCompany = false;
        if (res.data.taxNo) {
          if (this.billPage == 1) {
            this.billData = [];
            this.billData.push(res.data);
          } else {
            this.billData[0].child = this.billData[0].child.concat(res.data.child);
          }
        } else {
          this.billData = [];
        }
      });
    },
    changeItem(e) {
      let index = e.target.dataset.index;
      if (!index) return;
      this.item = index;
      this.searchSetIndex();
      this.getTable = true;
      if (this.item == 0) {
        this.getInvoiceGoodsList();
      }
      if (this.item == 1) {
        this.getInvoiceCategoryList();
      }
      if (this.item == 2) {
        this.getInvoiceList();
      }
      if (this.item == 3) {
        this.getInvoiceMonthList();
      }
    },
    searchItemChange() {
      //
      this.getTable = true;
      if (this.item == 0) {
        this.getInvoiceGoodsList();
      }
      if (this.item == 1) {
        this.getInvoiceCategoryList();
      }
      if (this.item == 2) {
        this.getInvoiceList();
      }
      if (this.item == 3) {
        this.getInvoiceMonthList();
      }
    },
    searchItem(val) {
      if (val == 1) {
        this.billPage = 1;
        this.searchSetIndex();
      }
      //
      this.searchCompany();
      //
      this.getTable = true;
      if (this.item == 0) {
        this.getInvoiceGoodsList();
      }
      if (this.item == 1) {
        this.getInvoiceCategoryList();
      }
      if (this.item == 2) {
        this.getInvoiceList();
      }
      if (this.item == 3) {
        this.getInvoiceMonthList();
      }
    },
    newSearchItem() {
      this.getTable = true;
      this.searchSetIndex();
      if (this.item == 0) {
        this.getInvoiceGoodsList();
      }
      if (this.item == 1) {
        this.getInvoiceCategoryList();
      }
      if (this.item == 2) {
        this.getInvoiceList();
      }
      if (this.item == 3) {
        this.getInvoiceMonthList();
      }
    },
    searchSetIndex() {
      this.pageNum0 = 1;
      this.pageNum1 = 1;
      this.pageNumpl = 1;
    },
    allResult() {
      this.billPage = 1;
      if (this.rangeTimes.start) {
        this.billTime = [];
        [this.billTime[0], this.billTime[1]] = [
          moment(this.rangeTimes.start).format("YYYY-MM-DD"),
          moment(this.rangeTimes.end).format("YYYY-MM-DD"),
        ];
      } else {
        this.billTime = "";
      }
      this.isActive = 4;
      this.searchSetIndex();
      this.searchItem();
    },
    lastYear() {
      this.billPage = 1;
      const end = new Date(this.rangeTimesFor);
      const start = new Date(this.rangeTimesFor).getFullYear();
      const startYear = new Date(start - 1, 0, 1);
      const endYear = start - 1 + "-" + "12" + "-" + "31";
      this.billTime = [moment(startYear).format("YYYY-MM-DD"), endYear];
      this.isActive = 3;
      this.searchSetIndex();
      this.searchItem();
    },
    nowYear() {
      this.billPage = 1;
      const end = new Date(this.rangeTimesFor);
      const start = new Date(this.rangeTimesFor).getFullYear();
      const startYear = new Date(start, 0, 1);
      this.billTime = [
        moment(startYear).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 2;
      this.searchSetIndex();
      this.searchItem();
    },
    nowMonth() {
      this.billPage = 1;
      const end = new Date(this.rangeTimesFor);
      const start = new Date(end.getFullYear(), end.getMonth(), 1);
      this.billTime = [
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 0;
      this.searchSetIndex();
      this.searchItem();
    },
    lastMonth() {
      this.billPage = 1;
      const oDate = new Date(this.rangeTimesFor);
      var year = oDate.getFullYear();
      var month = oDate.getMonth();
      var start, end;
      if (month == 0) {
        year--;
        start = new Date(year, 11, 1);
        end = new Date(year, 11, 31);
      } else {
        start = new Date(year, month - 1, 1);
        end = new Date(year, month, 0);
      }
      this.billTime = [
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 1;
      this.searchSetIndex();
      this.searchItem();
    },
    handleSelect(key) {
      this.billPage = 1;
      this.activeIndex = key;
      this.contentIndex = 0;
      this.relationTaxNo = "";
      this.searchSetIndex();
      this.searchItem();
      this.contentIndex = this.$route.query.taxNo;
    },
    searchCompany() {
      this.getMore = true;
      this.getTableCompany = true;
      if (this.activeIndex == 1) {
        this.getEntryCompany();
      } else {
        this.getSalesCompany();
      }
    },
    // 进项商品明细
    getEntryInvoiceGoods() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum0,
        pageSize: this.pageSize0,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[0],
        desensitizationStatus: this.$route.query.desensitization,
      };
      getEntryInvoiceGoodsList(params).then((res) => {
        this.totalNumber0 = Number(res.pageTotal);
        this.billTableDataItem0 = res.list;
        this.getTable = false;
      });
    },
    // 销项商品明细
    getSalesInvoiceGoods() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum0,
        pageSize: this.pageSize0,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[1],
        desensitizationStatus: this.$route.query.desensitization,
      };
      getSalesInvoiceGoodsList(params).then((res) => {
        this.totalNumber0 = Number(res.pageTotal);
        this.billTableDataItem0 = res.list;
        this.getTable = false;
      });
    },
    getInvoiceGoodsList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceGoods();
      } else {
        this.getSalesInvoiceGoods();
      }
    },
    // 进项品类统计
    getEntryInvoiceCategory() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNumpl,
        pageSize: this.pageSizepl,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[0],
      };
      getEntryInvoiceCategoryList(params).then((res) => {
        this.billTableDataItem1 = res.data.tableList;
        this.totalNumberpl = res.data.pageTotal;
        let billCircleData = res.data.imageList;
        this.billCircleData = [];
        for (let i in billCircleData) {
          this.billCircleData[i] = {};
          this.billCircleData[i].value = billCircleData[i].percent;
          this.billCircleData[i].name = billCircleData[i].productName;
        }
        this.showMap();
        this.getTable = false;
      });
    },
    // 销项品类统计
    getSalesInvoiceCategory() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNumpl,
        pageSize: this.pageSizepl,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[1],
      };
      getSalesInvoiceCategoryList(params).then((res) => {
        this.billTableDataItem1 = res.data.tableList;
        let billCircleData = res.data.imageList;
        this.totalNumberpl = res.data.pageTotal;
        this.billCircleData = [];
        for (let i in billCircleData) {
          this.billCircleData[i] = {};
          this.billCircleData[i].value = billCircleData[i].percent;
          this.billCircleData[i].name = billCircleData[i].productName;
        }
        this.showMap();
        this.getTable = false;
      });
    },
    getInvoiceCategoryList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceCategory();
      } else {
        this.getSalesInvoiceCategory();
      }
    },
    // 进项发票明细
    getEntryInvoice() {
      let params = {
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        // productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        // productNoList: this.productNoList[0],
        desensitizationStatus: this.$route.query.desensitization,
      };
      getEntryInvoiceList(params).then((res) => {
        this.totalNumber1 = Number(res.pageTotal);
        this.billTableDataItem2 = res.list;
        this.getTable = false;
      });
    },
    // 销项发票明细
    getSalesInvoice() {
      let params = {
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        // productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        // productNoList: this.productNoList[1],
        desensitizationStatus: this.$route.query.desensitization,
      };
      getSalesInvoiceList(params).then((res) => {
        this.totalNumber1 = Number(res.pageTotal);
        this.billTableDataItem2 = res.list;
        this.getTable = false;
      });
    },
    getInvoiceList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoice();
      } else {
        this.getSalesInvoice();
      }
    },
    // 进项每月进项
    getEntryInvoiceMonth() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[0],
      };
      getEntryInvoiceMonthList(params).then((res) => {
        this.billTableDataItem3 = res.data;
        this.getTable = false;
      });
    },
    // 销项每月进项
    getSalesInvoiceMonth() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
        invoiceStatusList: this.goodStatus,
        invoiceType: this.goodtype,
        productNoName: this.goodsFilter,
        taxRateDetailedList: this.taxRateFilter,
        riskList: this.activeIndex == 1 ? this.abnormalFilter1 : this.abnormalFilter2,
        productNoList: this.productNoList[1],
      };
      getSalesInvoiceMonthList(params).then((res) => {
        this.billTableDataItem3 = res.data;
        this.getTable = false;
      });
    },
    getInvoiceMonthList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceMonth();
      } else {
        this.getSalesInvoiceMonth();
      }
    },
    showMap() {
      this.chartColumn = echarts.init(document.getElementById("circle"));
      this.chartColumn.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b} : {d}%",
          // position: function (point) {
          //   return [point[0], "10%"];
          // },
          confine: true,
        },
        toolbox: {
          show: true,
          feature: {
            magicType: {
              show: true,
              type: ["pie", "funnel"],
              option: {
                funnel: {
                  x: "25%",
                  width: "50%",
                  funnelAlign: "left",
                  max: 1548,
                },
              },
            },
          },
        },
        calculable: true,
        series: [
          {
            name: "品类统计",
            type: "pie",
            radius: "80%",
            radius: ["40%", "80%"],
            color: [
              "#EC5757",
              "#BCA88D",
              "#2588ED",
              "#F7A659",
              "#95A5CD",
              "#FF409A",
              "#B8D4F9",
              "#ABDE8A",
              "#EED9BE",
              "#6293F8",
              "#878787",
            ],
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: this.billCircleData,
          },
        ],
      });
    },
  },
  // activated() {
  //   this.getgetDataDeleteText();
  // },
};
</script>

<style lang="stylus" scoped>
.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.bill-detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.0400);
  border-radius: 8px;
  margin: 20px 0 16px 0;
  padding: 0 24px 0 16px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0,0,0,0.7500);

  .time {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.4000);
  }
}

.bill-detail-content {
  // margin-top: 24px;
//   margin: 0 30px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding-bottom: 6px;

  .bill-detail-menu {
    position: relative;
    padding: 0 20px;
    background: #fff;
    padding-top: 6px;

    .getDataDeleteTextTip {
      position: absolute;
      right: 290px;
      top: 18px;
      color: #f00;
      font-size: 14px;
      z-index: 20;
    }

    .bill-detail-time {
      position: absolute;
      right: 20px;
      top: 18px;
      font-size: 14px;
      color: #86909C;
    }

    >>> .el-menu--horizontal > .el-menu-item {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 2px solid transparent !important;
      color: #86909C;
    }

    >>> .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 2px solid #2f54eb !important;
      font-size: 16px;
      font-weight: 600;
      color: #1D2129;
    }

    >>> .el-menu-item {
      padding: 0 0;
    }

    >>> .el-menu-item:first-child {
      margin-right: 40px;
    }
  }

  .bill-time-filter {
    display: flex;
    align-items: center;
    color: #86909C;
    padding: 12px 20px;
    font-size: 14px;

    >>> .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
      width: 240px;
      height: 32px;
      line-height: 32px;
    }

    >>> .el-date-editor .el-range-separator {
      line-height: 24px;
    }
    >>> .el-date-editor .el-range__icon {
      line-height: 24px;
    }
    >>> .el-date-editor .el-range__close-icon {
      line-height: 24px;
    }
    .search {
      width: 60px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      background: #165DFF;
      border-radius: 2px;
      margin: 0 0 0 16px;
      font-size: 14px;
      cursor: pointer;
    }
    .search:hover {
      background: rgba(47, 34, 235, 1);
    }
    >>> .el-divider--vertical {
      margin: 0 4px;
      height: 32px;
    }
    .search-month {
      background: #fff;
      color: #86909C;
      box-sizing: border-box;
      width: 60px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin-right: 10px;
      border-radius: 2px;
      cursor: pointer;
    }

    .month-active {
      background: rgba(22,93,255,0.1);
      font-weight: 600;
      color: #165DFF;
    }
  }
  .bill-time-table {
    display: flex;
    padding: 0 16px;

    scrollbar-color:  #E5E6EB #F7F8FA;  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
    scrollbar-width: thin;  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
    // -ms-overflow-style:none;  /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */

    >>> .content-c::-webkit-scrollbar {
      /* 滚动条整体样式 */
      width: 8px;
      /* 高宽分别对应横竖滚动条的尺寸 */
      height: 1px;
      position: relative;
      right: 4px;
    }
    >>> .content-c::-webkit-scrollbar-thumb {
      /* 滚动条里面小方块 */
      // border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #E5E6EB;
    }
    >>> .content-c::-webkit-scrollbar-track {
      /* 滚动条里面轨道 */
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      // border-radius: 5px;
      background: #F7F8FA;
    }

    .left {
      width: 30%;
      border: 1px solid #D9D9D9;
      position: relative;

      .content-c {
        max-height: calc(100vh - 206px);
        overflow-x: auto;
        // padding-bottom: 30px;

        .el-icon-tops {
          position: absolute;
          bottom: -10px;
          right: 0;
          color: #4e5969;
          font-size: 24px;
          padding: 30px;
          z-index: 3001;
          cursor: pointer;
        }
      }

      .content-flex {
        color: rgba(0,0,0,0.4500);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -32px;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0,0,0,0.7500);
        padding: 12px 8px;
        padding-right: 8px;
        box-sizing: border-box;
        .title-input {
          flex: 1;
          padding-right: 8px;
        }
        .title-company {
          white-space: nowrap;
          font-size: 14px;
          color: #86909C;
        }
        >>> .el-input {
          // max-width: 240px;
          height: 32px;
          line-height: 32px;
        }
        >>> .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        >>> .el-input__icon {
          height: 32px;
          line-height: 32px;
        }
      }

      .contentAct {
        background: #F0F5FF;

        .company {
          color: #1D2129 !important;
        }

        .price {
          color: #1D2129;
        }

        .info {
          color: #1D2129;
        }
      }

      .contents {
        position: relative;

        // border-bottom: 1px solid #EEEEEE;
        cursor: pointer;

        .content-item {
          padding: 16px 34px;
        }

        .content-item2 {
          padding: 6px 34px 6px 45px;
        }

        .content-right {
          position: absolute;
          top: 8px;
          color: #D8D8D8;
          left: -4px;
          font-size: 22px;
          padding: 10px;
        }

        .company {
          font-size: 16px;
          font-weight: 600;
          color: #4E5969;
          line-height: 22px;
        }

        .price {
          margin-bottom: 8px;
          margin-right: 16px;
        }

        .info {
          font-size: 14px;
          color: #4E5969;
        }
      }
      .contents:last-child {
        // border: none;
      }
    }
    .right {
      width: 70%;
      overflow: hidden;

      .right-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select {
          // margin-right: 42px;

          >>> .el-select {
            width: 106px;
            height: 32px;
          }
          >>> .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          >>> .el-input__icon {
            line-height: 32px;
          }
          .billStatus {
            margin-left: 24px;
          }
          //
        }

        .bill-select {
          font-size: 14px;
          color: #86909C;
          margin-right: 0;
        }
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #86909C;
        margin-left: 18px;
        cursor: pointer;
        line-height: 32px;

        >>> .el-divider--vertical {
          margin: 0 16px;
          height: 20px;
        }

        .bill-name {
          font-size: 14px;
          color: #165DFF;
        }

        .taxname-select {
          padding: 10px 8px;
          color: rgba(0,0,0,0.4000);
        }

        .bill-names {
          color: rgba(0,0,0,0.6000);
        }

        .taxname {
          // padding: 10px 14px;
        }

        .taxname:first-child {
          padding-left: 0;
        }
      }
      .select {

      }
      .item-table {
        position: relative;
        margin-top: 12px;
        margin-left: 10px;

        .el-table--border {
          border: none;
        }

        >>> .el-table__fixed {
          // height: auto !important;
          bottom: 10px !important;
        }

        .item-pagination {
          // position: absolute;
          // right: 0;
          // bottom: 10px;
          float: right;
          margin-top: 4px;
        }
        >>> .el-table td {
          // border: none;
        }

        >>> .el-table {
          // min-height: 330px;
        }

        >>> .el-table__body-wrapper {
          // z-index: 1001;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar {
          /* 滚动条整体样式 */
          width: 8px;
          /* 高宽分别对应横竖滚动条的尺寸 */
          height: 10px;
          position: relative;
          right: 4px;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
          /* 滚动条里面小方块 */
          // border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          background: #E5E6EB;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-track {
          /* 滚动条里面轨道 */
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          // border-radius: 5px;
          background: #F7F8FA;
        }

        >>> .el-table th .cell {
          line-height: 34px;
        }

        >>> .el-table td {
          padding: 11px 0;
        }

        >>> .el-table thead th {
          padding: 2px 0;
        }
      }
    }
  }
}

.circle-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 500px;

  .circle {
    position: relative;
    left: 20px;
    width: 250px;
    height: 250px;
    background: #fff;
  }

  .circle-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: space-between;
    margin-left: 60px;

    .circle-item-color {
      width: 8px;
      height: 16px;
      margin-right: 8px;
    }

    .bill-Data {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      width: 460px;
      font-size: 16px;
      color: rgba(0,0,0,0.6000);
      line-height: 22px;
      margin-bottom: 8px;

      .bill-Data-content {
        margin-right: 4px;
      }
    }
  }
}

.bill-content {
  width: 1240px;
  margin: 24px auto 30px;
  box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.07);
  margin-bottom: 20px;
}

.chart-img {
  margin-right: 4px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

.chart-img-right {
  margin-right: 0;
}

.bill-detail-info {
  position: absolute;
  left: 670px;
  top: 18px;
  font-size: 14px;
  color: #165dff;
  cursor: pointer;
}

.good-type {
  >>> .el-select {
    width: 120px;
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input {
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input__inner {
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input__icon {
    line-height: 34px;
  }
}

.bill-select-type {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #86909c;
  margin-left: 40px;
}

.billStatus {
  margin-left: 10px;
}

.copy-price {
  margin-left: 10px;
  color: #165dff;
  cursor: pointer;
  display: none;
}

.price:hover .copy-price {
  display: inline;
}

.risk-type {
  font-size: 14px;
  color: #165dff;
  cursor: pointer;
}

.block {
  >>> .el-cascader {
    width: 330px;
  }
}

.risk-type-active {
  color: #86909c;
}

.item3-company {
  font-size: 12px;
  color: rgba(0,0,0,0.4);
  border: 1px solid rgba(0,0,0,0.4);
  padding: 1px 2px;
  border-radius: 4px;
  margin-left: 4px;
}

.el-cascader-c {
  margin: 10px 0 0 90px;
}

.item-goods {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #86909c;

  >>> .el-input {
    height: 32px;
    line-height: 32px;
  }
  >>> .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  >>> .el-input__icon {
    height: 32px;
    line-height: 32px;
  }
  .more-filter {
    color: #165dff;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }
  .more-class {
    margin-left: 8px;
    cursor: pointer;
  }
}

.right-item-filter {
  font-size: 14px;
  font-weight: 600;
  color: #86909c;
  margin-left: -2px;

  .radio {
    display: flex;
    position: relative;
    // align-items: center;

    .rate {
      padding-right: 12px;
      width: 76px;
      text-align: right;
    }

    >>> .el-checkbox {
      margin-right: 9px;
    }
    >>> .el-checkbox:last-child {
      margin-right: 0;
    }

    >>> .el-checkbox__label {
      padding-left: 2px;
    }
  }

  .radio-top {
    margin-bottom: 4px;
  }
}

.item-goods {
  >>> .el-button {
    padding: 9px 12px;
    margin-left: 2px;
    background: #165dff;
    border-radius: 0;
  }
  >>> .el-input {
    width: 200px;
  }
}

.select-group {
  display: flex;
  align-items: center;
}

.clear-filter {
  margin-right: 6px;
  cursor: pointer;
  padding: 0 1px;
}

.get-moredata {
  text-align: center;
  color: #86909c;
  cursor: pointer;
}

.el-icon-relative {
  position: relative;
}

.el-icon {
  position: absolute;
  top: -6px;
  right: -6px;
}

.remark-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.good-type >>> .el-select__tags {
  max-width: none !important;
}

.el-icon-searchs {
  font-size: 16px;
  color: #165dff;
  cursor: pointer;
  padding: 4px;
}
</style>
