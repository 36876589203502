import { render, staticRenderFns } from "./UpDownCustomer.vue?vue&type=template&id=0e6ca167&scoped=true&"
import script from "./UpDownCustomer.vue?vue&type=script&lang=js&"
export * from "./UpDownCustomer.vue?vue&type=script&lang=js&"
import style0 from "./UpDownCustomer.vue?vue&type=style&index=0&id=0e6ca167&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6ca167",
  null
  
)

export default component.exports