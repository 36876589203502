<template>
  <div>
    <div v-loading="!loaded" style="min-height: 200px">
      <div class="toolbar">
        <el-form ref="form" :model="filter" :inline="true" class="section-toolbar">
          <el-form-item>
            <span>时间:</span>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              size="mini"
              style="width: 260px"
              value-format="yyyy-MM-dd"
              @change="loadCompanyAreaList"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item style="margin-right: 12px">
            <span>
              金额
              <el-tooltip
                class="item"
                effect="dark"
                content="表示不含税金额"
                placement="top"
                popper-class="box-item"
              >
                <i class="iconfont iconicon_tips"></i>
              </el-tooltip>
              :
            </span>
            <el-input
              v-model="filter.fromAmount"
              size="mini"
              style="width: 88px"
              @input="loadCompanyAreaList"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="~">
            <el-input
              v-model="filter.toAmount"
              size="mini"
              style="width: 128px"
              @input="loadCompanyAreaList"
              maxlength="9"
              placeholder="请输入"
            ></el-input>
            <span class="inputUnit">万元</span>
          </el-form-item>

          <el-form-item>
            <span
              >展示排名
              <el-tooltip
                class="item"
                effect="dark"
                content="展示金额排名前N的上游企业和下游企业"
                placement="top"
                popper-class="box-item"
              >
                <i class="iconfont iconicon_tips"></i>
              </el-tooltip>
              :
            </span>
            <BigPowerInput
              type="number"
              :min="1"
              v-model="filter.limit"
              size="mini"
              style="width: 60px"
              @input="loadCompanyAreaList"
            ></BigPowerInput>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox
              label="只显示风险企业"
              name="type"
              v-model="filter.showCompanyRisk"
              @change="loadCompanyAreaList"
            ></el-checkbox
            >&nbsp;
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="风险企业指单笔交易金额5万及以上的风险企业，如需完整的风险企业数据可拨打客服热线： 0571-85055930"
              placement="right-start"
              popper-class="box-item"
            >
              <i class="iconfont iconicon_tips"></i>
            </el-tooltip> -->
            <el-tooltip class="item" effect="dark" placement="top" popper-class="box-item">
              <div slot="content">
                下图中符合如下条件的企业会显示风险提示。 <br />
                1、自然年度内交易的价税合计金额排名前50(含)的上游企业和下游企业； <br />
                2、有风险的企业
              </div>
              <i class="iconfont iconicon_tips"></i>
            </el-tooltip>
          </el-form-item>
        </el-form>
        <button class="btn-link btn-fullscreen" @click="handleFullscreen">
          <i class="iconfont iconicon_tpqp" style="font-size: 14px"></i> <span>图谱全屏</span>
        </button>
      </div>
      <div>
        <NetworkChart :data="data" ref="networkChart"></NetworkChart>
      </div>
    </div>
    <el-dialog
      :visible.sync="fullScreenDialogVisible"
      :fullscreen="true"
      :modal="false"
      :title="''"
    >
      <button class="btn-link btn-fullscreen" @click="fullScreenDialogVisible = false" id="endBtn">
        <i class="iconfont iconicon_tpqp" style="font-size: 14px"></i> <span>退出全屏</span>
      </button>
      <NetworkChart :data="data" ref="networkChart2" height="100vh"></NetworkChart>
    </el-dialog>
  </div>
</template>

<script>
import NetworkChart from "@/pages/Result/ThreeYearResult/financialAnalysis/networkChart/NetworkChart";
import { getCompanyRiskImage } from "@/api/threeYears";

export default {
  name: "NetWorkChartWindow",
  components: {
    NetworkChart,
  },
  data() {
    return {
      loaded: false,
      data: null,
      fullScreenDialogVisible: false,
      filter: {
        time: [],
        fromAmount: "",
        toAmount: "",
        limit: 15,
        showCompanyRisk: false,
      },
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyAreaList();
    this.loaded = true;
  },
  methods: {
    handleFullscreen() {
      this.fullScreenDialogVisible = true;
      setTimeout(() => {
        this.$refs["networkChart2"].render();
      }, 500);
    },
    changeTime() {
      this.loadCompanyAreaList();
    },
    async loadCompanyAreaList() {
      const params = {};
      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      params.programId = this.$route.params.uuid;
      params.startMoney = this.filter.fromAmount;
      params.endMoney = this.filter.toAmount;
      params.showRiskListSize = this.filter.limit;
      params.showCompanyRisk = this.filter.showCompanyRisk;
      params.desensitizationStatus = this.$route.query.desensitization;
      const res = await getCompanyRiskImage(params);
      this.data = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar
  margin 20px 0;
  display flex
  justify-content space-between
  align-items center

  .btn-fullscreen
    font-size 14px
    position absolute;
    top: 154px;
    right: 30px;
    //width: 110px;
    //height: 36px;
    padding:8px 16px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(29,33,41,0.1);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .iconfont
      color:#2663F6;
    span
       margin:0 6px;



>>> .el-dialog__header
  border-bottom: 0 none;
  padding 0

.el-icon-warning-outline-dark {
  margin-left: 6px;
  cursor: pointer;
}
.section-toolbar{
  span{
    margin-right:10px;
    font-size: 14px;
    color:#606266;
  }
}
>>> .el-range-editor--mini.el-input__inner {
  height: 36px;
}
>>> .el-input--mini .el-input__inner {
  height: 36px;
}
>>>.el-dialog__headerbtn .el-dialog__close{
display:none
}
#endBtn{
  position: absolute;
  right: 28px;
  top: 41px;
  background: #fff;
  padding: 8px 16px;
}
.inputUnit{
 position: absolute;
 width: 28px;
 left: 89px
}
.iconicon_tips{
  font-size:14px;
  color: #606266;
}
</style>
