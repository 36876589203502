<template>
  <div><financialRisk @toTable="toTable"></financialRisk></div>
</template>
<script>
import financialRisk from "@/pages/Result/ThreeYearResult/financialAnalysis/enterpriseAffiliation";
export default {
  components: {
    financialRisk,
  },
  name: "relatedPartyTransactions",
  methods: {
    toTable() {
      this.$emit("toTable5")
    }
  },
};
</script>
