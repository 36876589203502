<template>
  <div>
    <div class="search-table-content-tip">
      温馨提示：此处仅统计企业所得税和增值税的关键数据，后续将增加更多税种数据
    </div>
    <div class="search-table-content maxheightLoaing" v-loading="maxheightLoaing">
      <BigPowerTable
        :data="zcfzbTable"
        class="table"
        v-if="zcfzbTable.body.length"
      ></BigPowerTable>
    </div>
  </div>
</template>

<script>
import { reportCompareCw } from "@/api/api";
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      ZcfzbZqList: null,
      ZcfzbDataList: null,
      zcfzbTable: {
        header: [],
        body: [],
      },
      zqName: this.$route.query.zq,
      uuid: this.$route.params.uuid,
      maxheightLoaing: true,
    };
  },

  components: {
    BigPowerTable,
  },

  mounted() {
    this.getZcfzbDataList();
  },

  methods: {
    getZcfzbDataList() {
      reportCompareCw(this.uuid, this.zqName).then((res) => {
        this.maxheightLoaing = false;
        this.ZcfzbZqList = res.data.zqList;
        this.ZcfzbDataList = res.data.groupResultList;
        this.zcfzbTable.header = [
          [
            {
              content: "类型",
              align: "center",
            },
            {
              content: "项目",
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
        ];
        this.sortTable();
      });
    },
    sortTable() {
      for (let i = 0; i < this.ZcfzbDataList.length; i++) {
        for (let j = 0; j < this.ZcfzbDataList[i].itemResultList.length; j++) {
          this.ZcfzbDataList[i].itemResultList[j].newArray = [];
          this.ZcfzbDataList[i].itemResultList[j].newArray[0] = {};
          this.ZcfzbDataList[i].itemResultList[j].newArray[1] = {};
          this.ZcfzbDataList[i].itemResultList[j].newArray[2] = {};
          this.ZcfzbDataList[i].itemResultList[j].newArray[3] = {};
          this.ZcfzbDataList[i].itemResultList[j].newArray[4] = {};

          // 1
          this.ZcfzbDataList[i].itemResultList[
            j
          ].newArray[0].content = this.ZcfzbDataList[i].groupName;
          this.ZcfzbDataList[i].itemResultList[j].newArray[0].align = "left";

          // 2
          this.ZcfzbDataList[i].itemResultList[
            j
          ].newArray[1].content = this.ZcfzbDataList[i].itemResultList[j].itemName;
          this.ZcfzbDataList[i].itemResultList[j].newArray[1].align = "left";

          // 3
          this.ZcfzbDataList[i].itemResultList[
            j
          ].newArray[2].content = this.ZcfzbDataList[i].itemResultList[j].valueList[0];
          this.ZcfzbDataList[i].itemResultList[j].newArray[2].align = "right";
          // 4
          this.ZcfzbDataList[i].itemResultList[
            j
          ].newArray[3].content = this.ZcfzbDataList[i].itemResultList[j].valueList[1];
          this.ZcfzbDataList[i].itemResultList[j].newArray[3].align = "right";
          // 5
          this.ZcfzbDataList[i].itemResultList[
            j
          ].newArray[4].content = this.ZcfzbDataList[i].itemResultList[j].valueList[2];
          this.ZcfzbDataList[i].itemResultList[j].newArray[4].align = "right";
        }
      }

      //   console.log(this.ZcfzbDataList);

      let dataArray = [];

      for (let i = 0; i < this.ZcfzbDataList.length; i++) {
        for (let j = 0; j < this.ZcfzbDataList[i].itemResultList.length; j++) {
          this.ZcfzbDataList[0].itemResultList[0].newArray[0].rowspan = this.ZcfzbDataList[0].itemResultList.length;
          this.ZcfzbDataList[1].itemResultList[0].newArray[0].rowspan = this.ZcfzbDataList[1].itemResultList.length;
          this.ZcfzbDataList[2].itemResultList[0].newArray[0].rowspan = this.ZcfzbDataList[2].itemResultList.length;
          dataArray.push(this.ZcfzbDataList[i].itemResultList[j].newArray);
        }
      }
      //   console.log(dataArray);
      this.zcfzbTable.body = dataArray;
    },
  },
};
</script>

<style lang="stylus" scoped>
.search-table-content-tip {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
  margin: 0 0 16px 10px;
}
.search-table-content {
  margin-left: 8px;
//   padding: 0 20px 20px 20px;
//     .table {
//       width: 88px;
//       height: 32px;
//       line-height: 32px;
//       background: #FFFFFF;
//       border-radius: 2px;
//       text-align: center;
//       color: #86909C;
//       font-size: 14px;
//       cursor: pointer;
//       margin-bottom: 18px;
//     }
}

.maxheightLoaing {
  min-height: 400px;
}
</style>
