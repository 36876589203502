import { render, staticRenderFns } from "./historicalSituation.vue?vue&type=template&id=7750ee42&scoped=true&"
import script from "./historicalSituation.vue?vue&type=script&lang=js&"
export * from "./historicalSituation.vue?vue&type=script&lang=js&"
import style0 from "./historicalSituation.vue?vue&type=style&index=0&id=7750ee42&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7750ee42",
  null
  
)

export default component.exports