<template>
  <div class="bg">
    <div class="menu-bar-wrapper">
      <div class="add-data" v-if="this.$route.query.fromHouTai == 'houtai'">
        房地产行业:
        <span @click="addData">补充数据</span>
      </div>
      <div class="main">
        <div class="menu-bar">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-bar"
            mode="horizontal"
            @select="handleTopMenuSelect"
          >
            <el-menu-item index="1" v-show="(tagIndex == 20 || tagIndex == 10|| tagIndex == 21)">企业财税分析</el-menu-item>
            <el-menu-item index="2">财税风险详情</el-menu-item>
            <el-menu-item index="5" v-if="showRelation&&(tagIndex == 20 || tagIndex == 10|| tagIndex == 21)">关联方交易</el-menu-item>
            <el-menu-item index="3" v-if="getTaxFirmFindInvoiceStatus&&(tagIndex == 20 || tagIndex == 10|| tagIndex == 21)">发票查询</el-menu-item>
            <el-menu-item index="4">报表查询</el-menu-item>
          </el-menu>
          <div class="residueData" v-if="activeIndex == 3 || activeIndex == 4">
            <div v-if="residueDay" style="display: flex; align-items: center; font-size: 14px">
              <el-tooltip
                class="item"
                effect="dark"
                content="“数据保留天数”从报告生成时的日期开始计算，若此处有做修改，则系统依旧从报告生成时的日期进行天数倒计时保留该页面数据！"
                placement="bottom-start"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
              <div>数据保留天数：</div>
              <div>
                <el-select @change="timeChange" v-model="timeValue" placeholder="请选择">
                  <el-option
                    v-for="item in timeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <!-- <div
              @click="deleteBill"
              class="deleteBill"
              v-if="activeIndex == '3' && deleteType != 1"
            >
              删除发票
            </div> -->
            <!--            <button class="btn btn-primary btn-share" style="margin-left: 20px"-->
            <!--                    @click="handlePdfDownload">预览PDF-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
    <div>
      <keep-alive>
        <FinancialAnalysis v-if="activeIndex == '1'"></FinancialAnalysis>
        <resultFinance
          :anchor="detailedAnchor"
          v-if="activeIndex == '2'"
          :reportType="3"
        ></resultFinance>
        <bill
          v-if="activeIndex == '3' && getTaxFirmFindInvoiceStatus"
          ref="bill"
          @deleteBillType="deleteBillType"
          @setTime="setTime"
          :timeText="timeText"
        ></bill>
        <search-table v-if="activeIndex == '4'" ref="tableText" @setTime="setTime"></search-table>
        <relatedPartyTransactions
          @toTable5="toTable5"
          v-if="activeIndex == '5'"
        ></relatedPartyTransactions>
      </keep-alive>
    </div>
    <DesensitizationDialog
      ref="DesensitizationDialog"
      @confirm="handleDesensitizationDialogConfirm"
    ></DesensitizationDialog>
    <!--  -->
    <fangdichan ref="fangdiChan"></fangdichan>
    <hydq ref="hydqComple"></hydq>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import FinancialAnalysis from "@/pages/Result/ThreeYearResult/financialAnalysis/FinancialAnalysis";
import enterpriseAffiliation from "@/pages/Result/ThreeYearResult/financialAnalysis/enterpriseAffiliation";

import bill from "@/pages/Result/ThreeYearResult/bill/detail";
import searchTable from "@/pages/Result/ThreeYearResult/searchTable/table";

import resultFinance from "@/pages/ResultEditor/ResultEditor";
import relatedPartyTransactions from "@/pages/Result/ThreeYearResult/financialAnalysis/relatedPartyTransactions";

import {
  deleteBill,
  getTaxFirmFindInvoice,
  getInvoiceAccess,
  getIntegrity,
  updateDataExpireTime,
  getDataDeleteText2,
  getTag
} from "@/api/api";
import DesensitizationDialog from "../../ResultEditor/components/DesensitizationDialog";

import hydq from "./complement/hydq.vue";

import fangdichan from "@/pages/Test/UploadTable/components/fangdichan.vue";

import { getCompanyInfo } from "@/api/threeYears";
// import ToolBar from "../../ResultEditor/components/ToolBar.vue";
const pdfBase = process.env.VUE_APP_PDF_ROOT;
export default {
  components: {
    FinancialAnalysis,
    bill,
    searchTable,
    resultFinance,
    DesensitizationDialog,
    enterpriseAffiliation,
    fangdichan,
    hydq,
    relatedPartyTransactions,
  },
  mounted() {
    this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    this.$eventBus.$on("switchToDetailTab", (data) => {
      const { anchor } = data;
      this.activeIndex = "2";
      this.detailedAnchor = anchor;
      this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    });
    this.taxFirmFindInvoice();
    //
    this.getTaxPaymentType();
    //
    this.getIntegrityByTaxNo();
    //
    this.getTimeIndex();
    //
    this.setTime();
    // 
    this.gettag();
  },
  name: "ThreeYearResult",
  data() {
    return {
      activeIndex: sessionStorage.getItem("threeActiveIndex") || "1",
      deleteType: 1,
      detailedAnchor: "",
      getTaxFirmFindInvoiceStatus: false,
      //
      showRelation: false,
      showRelationTime: "1692932082000", //2023-08-25 10:44:42时间戳
      //
      timeOptions: [],
      timeValue: "",
      residueDay: "", // 剩余天数
      timeText: "",
      tagIndex: 0
    };
  },
  computed: {},
  methods: {
    gettag() {
      getTag(this.$route.params.uuid).then(res=> {
        this.tagIndex = res.data*1;
        if(this.tagIndex == 20||this.tagIndex == 10|| this.tagIndex == 21) {
          this.activeIndex = "1"
        } else {
          this.activeIndex = "2"
        }
        if(sessionStorage.getItem("manua") == 100) {
          this.activeIndex = "4"
          sessionStorage.removeItem("manua");
        }
      })
    },
    timeChange() {
      this.$confirm("此操作将改变数据保留天数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateDataExpireTime(this.$route.params.uuid, this.timeValue).then((res) => {
            if (res.code == 200) {
              this.$refs.bill && this.$refs.bill.getgetDataDeleteText();
              this.$refs.tableText && this.$refs.tableText.getgetDataDeleteText();
              // this.$refs.tableText.init();
              this.$refs.tableText && this.$refs.tableText.init();
            }
            this.$message({
              message: res.message,
              type: res.code == 200 ? "success" : "warn",
            });
            this.setTime();
          });
        })
        .catch(() => {
          // this.$refs.bill && this.$refs.bill.getgetDataDeleteText();
          // this.$refs.tableText && this.$refs.tableText.getgetDataDeleteText();
          // this.$refs.tableText.init();
          this.setTime();
        });
    },
    setTime() {
      getDataDeleteText2(this.$route.query.uuid).then((res) => {
        this.timeValue = res.data.day;
        this.residueDay = res.data.residueDay;
        this.timeText = res.data.text;
        this.timeOptions = [
          {
            label: "7天",
            value: 7,
            disabled: +this.timeValue - this.residueDay > 7,
          },
          {
            label: "14天",
            value: 14,
            disabled: +this.timeValue - this.residueDay > 14,
          },
          {
            label: "30天",
            value: 30,
            disabled: +this.timeValue - this.residueDay > 30,
          },
          {
            label: "不保留",
            value: 0,
            // disabled: 30 > Number(this.residueDay),
          },
        ];
        this.$refs.bill && this.$refs.bill.getCurrentData();
      });
    },
    //
    getTimeIndex() {
      getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization,
      }).then((res) => {
        this.data = res.data;
        const date = new Date(this.data.exeDate);
        const timeStamp = date.getTime();
        if (Number(timeStamp) > Number(this.showRelationTime)) {
          this.showRelation = true;
        }
      });
    },
    toTable5() {
      let key = "4";
      this.handleTopMenuSelect(key);
    },
    //
    getIntegrityByTaxNo() {
      let params = {
        programId: this.$route.params.uuid,
        taxNo: this.$route.query.taxNo,
      };
      getIntegrity(params).then((res) => {
        if (!res.data) {
          this.$refs.hydqComple.hyDqDialog = true;
        }
      });
    },
    //
    handlePdfDownload() {
      this.$refs["DesensitizationDialog"].open();
    },
    handleDesensitizationDialogConfirm(isDesensitization) {
      isDesensitization = isDesensitization ? 1 : 0;
      const token = localStorage.getItem("authorization");
      const pdfDownloadUrl = `${pdfBase}/pdfFrontGenerator/${this.$route.params.uuid}/${this.$route.params.nsrsbh}?token=${token}&desensitization=${isDesensitization}`;
      window.open(
        pdfDownloadUrl,
        "_blank" // <- This is what makes it open in a new window.
      );
      this.$refs["DesensitizationDialog"].close();
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
      window.scrollTo(0, 0);
      this.detailedAnchor = "";
      sessionStorage.setItem("threeActiveIndex", key);
      this.$store.commit("ResultEditor/setOpenMode", { mode: "threeYears" });
    },
    //
    deleteBill() {
      this.$confirm(
        "发票删除后将不再显示发票信息，且无法恢复只能重新授权获取，是否删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBill(this.$route.params.uuid).then((res) => {
            this.$message({
              message: res.message,
              type: "success",
            });
            if (res.code == 200) {
              this.$refs.bill.getCurrentData();
            }
          });
        })
        .catch(() => {});
    },
    deleteBillType(data) {
      this.deleteType = data;
    },
    taxFirmFindInvoice() {
      getTaxFirmFindInvoice().then((res) => {
        this.getTaxFirmFindInvoiceStatus = res.data;
      });
    },
    // 查看是否存在错误数据  接口有点慢 无法在detect组件调用 只能放这慢慢调用等结果
    getTaxPaymentType() {
      getInvoiceAccess(this.$route.params.uuid).then((res) => {
        if (res.data.showData && res.data.text) {
          this.$notify({
            title: "警告",
            message: res.data.text,
            type: "warning",
            duration: 0,
          });
        }
        if (!res.data.showData) {
          this.$router.push({
            path: "/test/test-noData",
          });
        }
      });
    },
    //
    addData() {
      this.$refs.fangdiChan.getBaseInfo();
    },
    /* 切换 */
    changeActive(demo) {
      this.activeIndex = demo;
    },
    // handleBtnSaveClick() {},
    // handleSwitchTab(tabId) {
    //   this.tabId = tabId;
    // },
  },
};
</script>

<style lang="stylus" scoped>
.bg
  //background: #FDFDFD;
  background: #F6F8FA;
  padding-top: 56px;
  position: relative;

  .add-data
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #86909f;

    span
      color: rgb(47, 84, 235);
      cursor: pointer;
      margin-left: 4px;

.menu-bar-wrapper
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);

  .menu-bar
    position: relative;
    display flex
    justify-content space-between
    align-items center

    >>> .el-menu--horizontal
      .el-menu-item
        padding 0px 12px
        font-size: 16px;
        font-weight: 600;
        color: #4E5969;
        margin 0 25px
        height 42px;
        line-height 42px

      .el-menu-item.is-active
        border-bottom: 2px solid #165DFF;
        color: #165DFF;

    .btn-share
      padding 0
      width: 84px;
      height: 28px;
      background: #165DFF;
      border-radius: 2px;
      display flex;
      align-items center
      justify-content center

.deleteBill {
  color: #165DFF;
  cursor: pointer;
}
.el-icon-warning-outline {
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
}
>>>.menu-bar-wrapper .menu-bar[data-v-5dd7985a] .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 3px solid #165dff;
}
>>>.menu-bar-wrapper .menu-bar[data-v-5dd7985a] .el-menu--horizontal .el-menu-item{
  margin:0
  margin-right:80px;
  height: 56px;
  line-height: 56px;
  padding: 0;
}
.main {
  width: 1100px;
}

.residueData {
  position: absolute;
  right: 0;
  >>> .el-select {
    width: 100px;
  }
}
>>>.el-menu.el-menu--horizontal{
  border-bottom: solid 0px #e6e6e6;
}
>>>.menu-bar-wrapper .menu-bar .el-menu--horizontal .el-menu-item{
  height: 56px;
  line-height: 56px;
}
>>>.menu-bar-wrapper .menu-bar .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 3px solid #2663F6;
  color: #2663F6;
}
>>>.el-menu-item:hover{
  color: #2663F6 !important;
}
</style>
