<template>
  <div class="wrapper">
    <svg
      width="1060px"
      height="667px"
      viewBox="0 0 1157 667"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="01-企业财税分析" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="企业财税分析-2" transform="translate(-382.000000, -4698.000000)">
          <g id="PART6" transform="translate(360.000000, 4569.000000)">
            <g id="DBFX" transform="translate(22.000000, 129.000000)">
              <g
                id="编组-20"
                transform="translate(732.642846, 87.806801) rotate(90.000000) translate(-732.642846, -87.806801) translate(724.311045, -156.080699)"
                stroke="#BABCBE"
                stroke-linejoin="round"
              >
                <line
                  x1="4.5"
                  y1="247.979775"
                  x2="4.5"
                  y2="239.3875"
                  id="路径"
                  transform="translate(4.500000, 243.887500) rotate(90.000000) translate(-4.500000, -243.887500) "
                ></line>
                <line
                  x1="12.6636014"
                  y1="4.5"
                  x2="12.6636014"
                  y2="-3.5"
                  id="路径备份"
                  transform="translate(12.663601, 0.500000) rotate(90.000000) translate(-12.663601, -0.500000) "
                ></line>
                <line
                  x1="12"
                  y1="491.275"
                  x2="12"
                  y2="483.275"
                  id="路径"
                  transform="translate(12.000000, 487.275000) rotate(90.000000) translate(-12.000000, -487.275000) "
                ></line>
                <line
                  x1="8.525"
                  y1="487.275"
                  x2="8.525"
                  y2="0.5"
                  id="路径"
                  transform="translate(8.525000, 243.887500) rotate(180.000000) translate(-8.525000, -243.887500) "
                ></line>
              </g>
              <g id="编组-5" transform="translate(796.530346, 176.475000)">
                <g
                  id="编组-21备份-2"
                  transform="translate(180.250000, 8.000000) rotate(90.000000) translate(-180.250000, -8.000000) translate(172.250000, -92.250000)"
                  stroke="#BABCBE"
                  stroke-linejoin="round"
                >
                  <line
                    x1="4"
                    y1="104.5"
                    x2="4"
                    y2="96.5"
                    id="路径"
                    transform="translate(4.000000, 100.500000) rotate(90.000000) translate(-4.000000, -100.500000) "
                  ></line>
                  <line
                    x1="12"
                    y1="4.5"
                    x2="12"
                    y2="-3.5"
                    id="路径"
                    transform="translate(12.000000, 0.500000) rotate(90.000000) translate(-12.000000, -0.500000) "
                  ></line>
                  <line
                    x1="12"
                    y1="203.870193"
                    x2="12"
                    y2="195.870193"
                    id="路径"
                    transform="translate(12.000000, 199.870193) rotate(90.000000) translate(-12.000000, -199.870193) "
                  ></line>
                  <line
                    x1="9"
                    y1="200.5"
                    x2="9"
                    y2="0.5"
                    id="路径"
                    transform="translate(9.000000, 100.500000) rotate(180.000000) translate(-9.000000, -100.500000) "
                  ></line>
                </g>
                <g id="杜邦分析-单元组件备份-3" transform="translate(200.000000, 16.500000)">
                  <g id="杜邦分析-上" fill-rule="nonzero">
                    <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                    <text
                      id="净资产收益率"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                      fill="#FFFFFF"
                    >
                      <tspan x="17" y="25">所有者权益（万元）</tspan>
                    </text>
                  </g>
                  <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                    <rect
                      id="矩形备份"
                      fill-opacity="0.100000001"
                      fill="#2663F6"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="160"
                      height="40"
                      rx="18"
                      ry="1"
                    ></rect>
                    <g
                      id="杜邦分析-内容文字"
                      transform="translate(35.000000, 10.000000)"
                      fill="#1D2129"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                    >
                      <text id="5.33%" text-anchor="middle">
                        <tspan x="40" y="15">{{ data["所有者权益"] }}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
                <g id="杜邦分析-单元组件备份-4" transform="translate(0.000000, 16.500000)">
                  <g id="杜邦分析-上" fill-rule="nonzero">
                    <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                    <text
                      id="净资产收益率"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                      fill="#FFFFFF"
                    >
                      <tspan x="24" y="25">资产总额（万元）</tspan>
                    </text>
                  </g>
                  <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                    <rect
                      id="矩形备份"
                      fill-opacity="0.100000001"
                      fill="#2663F6"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="160"
                      height="40"
                    ></rect>
                    <g
                      id="杜邦分析-内容文字"
                      transform="translate(35.000000, 10.000000)"
                      fill="#1D2129"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                    >
                      <text id="5.33%" text-anchor="middle">
                        <tspan x="47" y="15">{{ data["资产总额"] }}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
                <g id="杜邦分析-单元组件备份-15" transform="translate(0.000000, 134.175000)">
                  <g id="杜邦分析-上" fill-rule="nonzero">
                    <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                    <text
                      id="净资产收益率"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                      fill="#FFFFFF"
                    >
                      <tspan x="24" y="25">流动资产（万元）</tspan>
                    </text>
                  </g>
                  <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                    <rect
                      id="矩形备份"
                      fill-opacity="0.100000001"
                      fill="#2663F6"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="160"
                      height="40"
                    ></rect>
                    <g
                      id="杜邦分析-内容文字"
                      transform="translate(35.000000, 10.000000)"
                      fill="#1D2129"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                    >
                      <text id="5.33%" text-anchor="middle">
                        <tspan x="47" y="15">{{ data["流动资产"] }}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
                <g id="杜邦分析-单元组件备份-16" transform="translate(0.000000, 250.225000)">
                  <g id="杜邦分析-上" fill-rule="nonzero">
                    <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                    <text
                      id="净资产收益率"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                      fill="#FFFFFF"
                    >
                      <tspan x="17" y="25">非流动资产（万元）</tspan>
                    </text>
                  </g>
                  <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                    <rect
                      id="矩形备份"
                      fill-opacity="0.100000001"
                      fill="#2663F6"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="160"
                      height="40"
                    ></rect>
                    <g
                      id="杜邦分析-内容文字"
                      transform="translate(35.000000, 10.000000)"
                      fill="#1D2129"
                      font-family="PingFangSC-Semibold, PingFang SC"
                      font-size="14"
                      font-weight="500"
                    >
                      <text id="5.33%" text-anchor="middle">
                        <tspan x="47" y="15">{{ data["非流动资产"] }}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
                <text
                  id="÷"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="20"
                  font-weight="normal"
                  fill="#1D2129"
                >
                  <tspan x="175.5" y="60.675">÷</tspan>
                </text>
                <text
                  id="+"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="20"
                  font-weight="normal"
                  fill="#1D2129"
                >
                  <tspan x="73.5" y="239.225">+</tspan>
                </text>
                <text
                  id="=备份"
                  font-family="PingFangSC-Regular, PingFang SC"
                  font-size="20"
                  font-weight="normal"
                  fill="#1D2129"
                >
                  <tspan x="73.5" y="121.563997">=</tspan>
                </text>
              </g>
              <g
                id="编组-21备份-4"
                transform="translate(481.261813, 185.237500) rotate(90.000000) translate(-481.261813, -185.237500) translate(472.499313, -0.768967)"
                stroke="#BABCBE"
                stroke-linejoin="round"
              >
                <line
                  x1="4"
                  y1="182.512934"
                  x2="4"
                  y2="174.512934"
                  id="路径"
                  transform="translate(4.000000, 178.512934) rotate(90.000000) translate(-4.000000, -178.512934) "
                ></line>
                <line
                  x1="12.525"
                  y1="5.5"
                  x2="12.525"
                  y2="-4.5"
                  id="路径"
                  transform="translate(12.525000, 0.500000) rotate(90.000000) translate(-12.525000, -0.500000) "
                ></line>
                <line
                  x1="12"
                  y1="374.388473"
                  x2="12"
                  y2="366.388473"
                  id="路径"
                  transform="translate(12.000000, 370.388473) rotate(90.000000) translate(-12.000000, -370.388473) "
                ></line>
                <line
                  x1="8.525"
                  y1="371.525869"
                  x2="8.525"
                  y2="0.0129344551"
                  id="路径"
                  transform="translate(8.525000, 186.012934) rotate(180.000000) translate(-8.525000, -186.012934) "
                ></line>
              </g>
              <g id="杜邦分析-单元组件" transform="translate(652.642846, 0.000000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="38" y="25">净资产收益率</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["净资产收益率"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份" transform="translate(896.030346, 95.475000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="17" y="25">权益乘数（杠杆率）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["权益乘数（杠杆率）"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-5" transform="translate(408.755346, 95.475000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="38" y="25">总资产净利率</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["总资产净利率"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-8" transform="translate(588.750000, 192.975000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="38" y="25">总资产周转率</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["总资产周转率"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-10" transform="translate(196.250000, 310.650000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="38" y="25">考虑利息负担</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["考虑利息负担"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-12" transform="translate(392.500000, 310.650000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="38" y="25">考虑税负因素</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["考虑税负因素"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-13" transform="translate(588.750000, 310.125000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="17" y="25">营业总收入（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["营业总收入"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-14" transform="translate(588.750000, 426.700000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="10" y="25">平均资产总额（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(33.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["平均资产总额"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-9" transform="translate(216.250000, 192.975000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="45" y="25">销售净利率</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["销售净利率"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-11" transform="translate(0.000000, 310.650000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="40"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="45" y="25">经营利润率</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 40.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["经营利润率"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-17" transform="translate(0.000000, 426.700000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="29.572" y="25">EBIT息税前利润</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.00000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["EBIT息税前利润"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-21" transform="translate(196.250000, 426.700000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="52" y="25">利润总额</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["利润总额"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-23" transform="translate(392.500000, 426.700000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="59" y="25">净利润</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["净利润"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-20" transform="translate(0.000000, 566.200000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="45" y="25">营业总收入</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["营业总收入"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-22" transform="translate(196.250000, 566.200000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="29.572" y="25">EBIT息税前利润</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.00000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["EBIT息税前利润"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="杜邦分析-单元组件备份-24" transform="translate(392.500000, 566.200000)">
                <g id="杜邦分析-上" fill-rule="nonzero">
                  <rect id="矩形" fill="#2663F6" x="0" y="0" width="160" height="60"></rect>
                  <text
                    id="净资产收益率"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="52" y="25">利润总额</tspan>
                    <tspan x="52" y="45">（万元）</tspan>
                  </text>
                </g>
                <g id="杜邦分析-下" transform="translate(0.000000, 60.000000)">
                  <rect
                    id="矩形备份"
                    fill-opacity="0.100000001"
                    fill="#2663F6"
                    fill-rule="nonzero"
                    x="0"
                    y="0"
                    width="160"
                    height="40"
                  ></rect>
                  <g
                    id="杜邦分析-内容文字"
                    transform="translate(35.000000, 10.000000)"
                    fill="#1D2129"
                    font-family="PingFangSC-Semibold, PingFang SC"
                    font-size="14"
                    font-weight="500"
                  >
                    <text id="5.33%" text-anchor="middle">
                      <tspan x="47" y="15">{{ data["利润总额"] }}</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <text
                id="×"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="726.142846" y="141">×</tspan>
              </text>
              <text
                id="÷备份"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="662.505346" y="416.4125">÷</tspan>
              </text>
              <text
                id="="
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="662.505346" y="298.038997">=</tspan>
              </text>
              <text
                id="×"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="369.494654" y="365.65">×</tspan>
              </text>
              <text
                id="×备份-2"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="171.244654" y="365.65">×</tspan>
              </text>
              <text
                id="÷"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="73.5" y="553.7">÷</tspan>
              </text>
              <text
                id="="
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="73.5" y="416.4125">=</tspan>
              </text>
              <text
                id="÷备份-2"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="268.755346" y="553.7">÷</tspan>
              </text>
              <text
                id="="
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="268.755346" y="416.4125">=</tspan>
              </text>
              <text
                id="÷备份-3"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="467" y="553.7">÷</tspan>
              </text>
              <text
                id="="
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="467" y="416.4125">=</tspan>
              </text>
              <text
                id="×"
                font-family="PingFangSC-Regular, PingFang SC"
                font-size="20"
                font-weight="normal"
                fill="#1D2129"
              >
                <tspan x="482.255346" y="237.15">×</tspan>
              </text>
              <g
                id="编组-20备份"
                transform="translate(276.500000, 291.038997) rotate(90.000000) translate(-276.500000, -291.038997) translate(258.436003, 94.538997)"
                stroke="#BABCBE"
                stroke-linejoin="round"
              >
                <line
                  x1="4"
                  y1="200.5"
                  x2="4"
                  y2="192.5"
                  id="路径"
                  transform="translate(4.000000, 196.500000) rotate(90.000000) translate(-4.000000, -196.500000) "
                ></line>
                <line
                  x1="22.0889972"
                  y1="210.538997"
                  x2="22.0889972"
                  y2="182.461003"
                  id="路径"
                  transform="translate(22.088997, 196.500000) rotate(90.000000) translate(-22.088997, -196.500000) "
                ></line>
                <line
                  x1="21.0639972"
                  y1="14.5389972"
                  x2="21.0639972"
                  y2="-13.5389972"
                  id="路径备份"
                  transform="translate(21.063997, 0.500000) rotate(90.000000) translate(-21.063997, -0.500000) "
                ></line>
                <line
                  x1="21.0639972"
                  y1="406.538997"
                  x2="21.0639972"
                  y2="378.461003"
                  id="路径"
                  transform="translate(21.063997, 392.500000) rotate(90.000000) translate(-21.063997, -392.500000) "
                ></line>
                <line
                  x1="8.025"
                  y1="392.5"
                  x2="8.025"
                  y2="0.5"
                  id="路径"
                  transform="translate(8.025000, 196.500000) rotate(180.000000) translate(-8.025000, -196.500000) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "DubunSvg",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus" scoped></style>
