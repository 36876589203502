<template>
  <div class="search-table">
    <div class="search-table-tab">
      <div class="getDataDeleteTextTip">{{ timeText2 }}</div>
      <div class="search-table-retry" @click="retryReport" v-show="activeName == 0">
        重新生成报告
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="报表查询" name="0"></el-tab-pane>
        <el-tab-pane label="报表对比" name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="search-table-content" v-if="activeName == 1">
      <div class="tab">
        <div
          class="table"
          :class="{ 'table-active': tableActive == 1 }"
          @click="tableActive = 1"
        >
          财务报表
        </div>
        <div
          class="table data"
          :class="{ 'table-active': tableActive == 2 }"
          @click="tableActive = 2"
        >
          税务数据
        </div>

        <div class="table data" style="margin-left: 930px">
          <el-button size="small" @click="exportTable()"> 导出excel </el-button>
        </div>
      </div>
      <div v-if="tableActive == 1">
        <div class="table-type">
          <div class="table-type-radio">
            <div>报表名称：</div>
            <div>
              <el-radio-group v-model="typeRadio" @change="typeRadioChange">
                <el-radio :label="1">资产负债表</el-radio>
                <el-radio :label="2">利润表</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div v-if="typeRadio == 1">
          <zcfzb ref="zcfzb"></zcfzb>
        </div>
        <div v-if="typeRadio == 2">
          <lrb ref="lrb"></lrb>
        </div>
      </div>
      <div v-if="tableActive == 2">
        <cw></cw>
      </div>
    </div>
    <div v-if="activeName == 0">
      <tab-upload @getNewTableData="getNewTableData" ref="initTable"></tab-upload>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import {
  reportCompare,
  longPeriodFiscalRetry,
  exportTableApi,
  getDataDeleteText,
  getDataDeleteText2
} from "@/api/api";
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { cloneDeep } from "lodash";

//
import zcfzb from "./components/zcfzb";
import lrb from "./components/lrb";
import cw from "./components/cw";

import tabUpload from "@/pages/Result/ThreeYearResult/bill/tableUpload/upload";

export default {
  data() {
    return {
      timeText2: "",
      //
      activeName: 0,
      tableActive: 1,
      typeRadio: 1,
      typeCheckList: ["显示占比", "显示较上期变动"],
      ZcfzbDataList: null,
      ZcfzbZqList: null,
      table: null,
      zcfzbTable: {
        header: [],
        body: [],
      },
      copyBody: [],
      copyHeader: [],
      tableType: "CW_ZCFZB", // CW_ZCFZB
      tableTypeList: ["CW_ZCFZB", "CW_LRB"],
      zqName: this.$route.query.zq,
      uuid: this.$route.params.uuid,
      getDataDeleteTextTip: "",
      // programId: this.$route.params.uuid,
      zq: this.$route.query.zq,
      // programId: "5de18496a9e4446e894e1a4cd1b32905",
    };
  },

  components: {
    BigPowerTable,
    tabUpload,
    zcfzb,
    lrb,
    cw,
  },

  mounted() {
    // this.getZcfzbDataList();
    // this.getgetDataDeleteText();
  },

  methods: {
    init() {
      this.$refs.initTable.init();
    },
    getgetDataDeleteText() {
      getDataDeleteText2(this.uuid).then((res) => {
        this.timeText2 = res.data.text;
        // this.$emit("setTime")
      });
    },
    retryReport() {
      this.$confirm("是否重新生成报告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          longPeriodFiscalRetry(this.uuid).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.$router.push({
                name: "test-log",
              });
            } else {
              this.$alert(res.message, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            }
          });
        })
        .catch(() => {});
    },
    getNewTableData() {
      // this.getZcfzbDataList();
      this.$refs.zcfzb.getZcfzbDataList();
      this.$refs.lrb.getZcfzbDataList();
    },
    typeRadioChange() {
      // this.tableType = this.tableTypeList[this.typeRadio - 1];
      // this.getZcfzbDataList();
    },
    handleClick() {},

    getZcfzbDataList() {
      reportCompare(this.uuid, this.tableType, this.zqName).then((res) => {
        this.ZcfzbDataList = res.data.itemResultList;
        this.ZcfzbZqList = res.data.zqList;
        this.zcfzbTable.header = [
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
          ],
        ];
        this.copyHeader = this.zcfzbTable.header;
        this.sortTable();
        this.typeChange();
      });
    },

    sortTable() {
      for (let i = 0; i < this.ZcfzbDataList.length; i++) {
        // 赋值
        this.zcfzbTable.body[i] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        this.zcfzbTable.body[i][0].content = this.ZcfzbDataList[i].itemName;
        this.zcfzbTable.body[i][1].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].itemValue;
        this.zcfzbTable.body[i][2].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].percent;
        this.zcfzbTable.body[i][3].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].range;
        this.zcfzbTable.body[i][4].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].itemValue;
        this.zcfzbTable.body[i][5].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].percent;
        this.zcfzbTable.body[i][6].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].range;
        this.zcfzbTable.body[i][7].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].itemValue;
        this.zcfzbTable.body[i][8].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].percent;
        this.zcfzbTable.body[i][9].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].range;

        //对其方式
        this.zcfzbTable.body[i][0].align = "left";
        this.zcfzbTable.body[i][1].align = "right";
        this.zcfzbTable.body[i][2].align = "right";
        this.zcfzbTable.body[i][3].align = "right";
        this.zcfzbTable.body[i][4].align = "right";
        this.zcfzbTable.body[i][5].align = "right";
        this.zcfzbTable.body[i][6].align = "right";
        this.zcfzbTable.body[i][7].align = "right";
        this.zcfzbTable.body[i][8].align = "right";
        this.zcfzbTable.body[i][9].align = "right";

        // 只显示重要科目
        this.zcfzbTable.body[i][0].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][1].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][2].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][3].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][4].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][5].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][6].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][7].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][8].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][9].importTag = this.ZcfzbDataList[i].importTag;

        // 设置筛选项
        this.zcfzbTable.body[i][0].rows = 0; // 0 标题 数值   1占比    2变动
        this.zcfzbTable.body[i][1].rows = 0;
        this.zcfzbTable.body[i][2].rows = 1;
        this.zcfzbTable.body[i][3].rows = 2;
        this.zcfzbTable.body[i][4].rows = 0;
        this.zcfzbTable.body[i][5].rows = 1;
        this.zcfzbTable.body[i][6].rows = 2;
        this.zcfzbTable.body[i][7].rows = 0;
        this.zcfzbTable.body[i][8].rows = 1;
        this.zcfzbTable.body[i][9].rows = 2;
      }

      this.copyBody = cloneDeep(this.zcfzbTable.body);
    },
    typeChange() {
      if (
        this.typeCheckList.includes("显示占比") &&
        this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = this.copyHeader;
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 5;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        this.typeCheckList.includes("显示占比") &&
        !this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 2;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        !this.typeCheckList.includes("显示占比") &&
        this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 1;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        !this.typeCheckList.includes("显示占比") &&
        !this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
          ],
          [
            {
              content: "资产",
              align: "center",
              rowspan: 2, //这个数用变量控制
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows == 0;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
    },
    checkImportTag(items) {
      if (this.typeCheckList.includes("只显示重要科目")) {
        for (let i = 0; i < items.length; i++) {
          this.zcfzbTable.body = items.filter((item) => {
            return item[0].importTag;
          });
        }
      } else {
        for (let i = 0; i < items.length; i++) {
          this.zcfzbTable.body = items.filter((item) => {
            return item[0];
          });
        }
      }
    },

    async exportTable() {
      let baseIndex = process.env.VUE_APP_API_ROOT_INDEX;
      //   console.log(this.$route.query.zq);
      // console.log(this.$route.params.uuid);
      //localhost:8080/always/edit/5de18496a9e4446e894e1a4cd1b32905/166236297935880268/ThreeYearResult?zq=2022004&
      //zqLocation=2022004&uuid=5de18496a9e4446e894e1a4cd1b32905&nsrsbh=166236297935880268&taxNo=91330104MA28N5MD3K&version=20&
      //companyName=%E6%B5%99%E6%B1%9F%E5%87%AF%E7%95%94%E5%95%86%E8%B4%B8%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8

      //test.ai-tax.cn/admin/reportCompare/exportReportExcel?programId=18d29d734f434910b8bb634564892fc2&zq=2022004
      let url =
        baseIndex +
        "/admin/reportCompare/exportReportExcel?programId=" +
        this.uuid +
        "&zq=" +
        this.zq;
      this.$message({
        type: "info",
        message: "请稍等~",
      });
      await exportTableApi(this.$route.params.uuid, this.zq).then((res) => {
        if (res) {
          window.open(url);
        }
      });
      // console.log(this.$route.params.uuid);

      //5de18496a9e4446e894e1a4cd1b32905
      // this.downloadHandler(res,'1',null)
      // console.log(res);
      // const blob = new Blob([res],{type: 'application/vnd.ms-excel'})
      //       const linkNode = document.createElement('a');
      //       linkNode.style.display = 'none';
      //       linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
      //       document.body.appendChild(linkNode);
      //       linkNode.click();  //模拟在按钮上的一次鼠标单击
      //       URL.revokeObjectURL(linkNode.href); // 释放URL 对象
      //       document.body.removeChild(linkNode);
    },
  },

  activated() {
    this.getgetDataDeleteText();
    this.init();
  },
};
</script>

<style lang="stylus" scoped>
.search-table {
  width: 1240px;
  background: #FFFFFF;
  box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.07);
  margin: 24px auto 30px;

  >>> .el-tabs__nav-wrap::after {
    height: 1px;
  }

  .search-table-tab {
    position: relative;
    padding: 16px 20px 0 20px;

    .getDataDeleteTextTip {
      position: absolute;
      right: 182px;
      top: 28px;
      color: red;
      font-size: 14px;
    }

    .search-table-retry {
      position: absolute;
      width: 116px;
      line-height: 26px;
      height: 28px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #165DFF;
      text-align: center;
      color: #165DFF;
      right: 20px;
      top: 23px;
      font-size: 14px;
      cursor: pointer;
      z-index: 199;
      box-sizing: border-box;
    }
  }

  >>> .el-tabs__nav-scroll {
    // padding-left: 20px;
  }

  >>> .el-tabs__item.is-active {
    color: #1D2129;
    font-weight: 600;
  }

  >>> .el-tabs__item {
    color: #86909C;
    font-size: 16px;
  }

  >>> .el-tabs__item:hover {
    color: #165DFF;
  }

  .search-table-content {
    position: relative;
    padding: 0 20px 20px 20px;

    .tab {
      display: flex;
      align-items: center;

      .table {
        width: 88px;
        height: 32px;
        line-height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        text-align: center;
        color: #86909C;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 18px;
      }

      .table-active {
        background: rgba(22,93,255,0.1);
        border-radius: 2px;
        color: #165DFF;
        font-weight: 600;
      }
    }

    .table-type {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: #86909C;
      font-size: 14px;

      .table-type-radio {
        display: flex;
        align-items: center;
        position: absolute;
        top: 68px;
        left: 22px;
      }

      >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #165DFF;
      }

      .type-checkList {
        margin-left: 80px;
      }
    }

    .table-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 29px 30px 14px 30px;
      font-weight: 600;
      color: #1D2129;
      line-height: 28px;

      .unit {
        font-size: 14px;
        font-weight: 400;
        color: #86909C;
      }

      .unit-dis {
        color: transparent;
      }
    }
  }
}
</style>
