<template>
  <el-dialog
    title="为提升报告的准确性，请补充本企业在电子税务局登记的地区和行业信息。"
    :visible.sync="hyDqDialog"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
    custom-class="hydq-dialog"
    v-loading="hyloading"
  >
    <div class="complete-item">
      <div>所属地区：</div>
      <el-cascader
        v-model="completeInfo.dq"
        :options="city"
        placeholder="请选择"
      ></el-cascader>
    </div>
    <div class="complete-item">
      <div>所属行业：</div>
      <el-select
        v-model="completeInfo.hy"
        placeholder="请输入"
        filterable
        :filter-method="filterCheckPerOptions"
      >
        <el-option
          v-for="item in hyOptions300"
          :key="item.hy_dm"
          :label="item.hymc"
          :value="item.hy_dm"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitHyDqDialog">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getTradeList,
  getRegion,
  supplementIndustryAndArea,
  longPeriodFiscalRetry,
} from "@/api/api";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      hyDqDialog: false,
      completeInfo: {},
      hyOptions: [],
      hyOptions300: [],
      areaTree: [],
      hyloading: false,
    };
  },
  mounted() {
    this.gettradeList();
    this.getRegionData();
  },
  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
  methods: {
    //
    filterCheckPerOptions: function (query = "") {
      var arr = this.hyOptions.filter((item) => {
        return item.hymc.includes(query);
      });
      if (arr.length > 100) {
        this.hyOptions300 = arr.slice(0, 100);
      } else {
        this.hyOptions300 = arr;
      }
    },

    //
    submitHyDqDialog() {
      if ([this.completeInfo.dq, this.completeInfo.hy].includes("" || undefined)) {
        this.$message({
          message: "行业地区不能为空！",
          type: "warning",
        });
        return false;
      }
      this.supplementhyDq();
    },
    gettradeList() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.hyOptions = data;
        this.hyOptions300 = this.hyOptions.slice(0, 200);
      });
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    async supplementhyDq() {
      let params = {
        companyNo: this.$route.query.taxNo,
        dq: this.completeInfo.dq.toString(),
        hy: this.completeInfo.hy,
        programId: this.$route.params.uuid,
      };
      this.hyloading = true;
      let dqAndHy = await supplementIndustryAndArea(params);
      if (dqAndHy.code != 200) {
        this.hyloading = false;
        this.$message({
          message: dqAndHy.message,
          type: "warning",
        });
        return;
      }
      let retryStatus = await longPeriodFiscalRetry(this.$route.params.uuid);
      if (retryStatus.code == 200) {
        this.hyDqDialog = false;
        

        this.$confirm("补充成功，是否关闭当前页面?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            window.close();
          })
          .catch(() => {});
      } else {
        this.$message({
          message: retryStatus.message,
          type: "warning",
        });
      }
      this.hyloading = false;
    },
  },
};
</script>

<style lang="stylus">
.hydq-dialog {

  .el-dialog__header {
    text-align: left;
    padding-bottom: 0;

    .el-dialog__title {
      font-size: 16px;
    }
  }

  .complete-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .el-input {
      width: 255px;
    }

    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }

    .el-input__icon {
      line-height: 36px;
    }
  }
}
</style>
