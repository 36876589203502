<template>
  <div v-if="data">
    <div class="wrapper">
      <div class="map">
        <ChinaCity
          ref="map"
          @click="handleMapClick"
          :geo-coord-map="data.shAreaMap"
          :type="type"
          :value-data="valueData"
          :self="data.companyDistribution"
        ></ChinaCity>
        <div class="legends">
          <div class="legend-group">
            <div class="legend">
              <img
                class="symbol"
                src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/icon_triangle.png"
              />
              表示本公司地点
            </div>
          </div>
          <div class="legend-group" v-if="type == 2">
            <div class="legend">销售额占比：</div>
            <div class="legend">
              <div class="box" style="background: #207dfb"></div>
              X≥50%
            </div>
            <div class="legend">
              <div class="box" style="background: #6eaafc"></div>
              50%＞X≥30%
            </div>
            <div class="legend">
              <div class="box" style="background: #aed0ff"></div>
              30%＞X≥20%
            </div>
            <div class="legend">
              <div class="box" style="background: #e0edff"></div>
              20%>X>0%
            </div>
          </div>
          <div class="legend-group" v-else-if="type == 1">
            <div class="legend">销售额占比：</div>
            <div class="legend">
              <div class="box" style="background: #207dfb"></div>
              X≥50%
            </div>
            <div class="legend">
              <div class="box" style="background: #6eaafc"></div>
              50%＞X≥30%
            </div>
            <div class="legend">
              <div class="box" style="background: #aed0ff"></div>
              30%＞X≥20%
            </div>
            <div class="legend">
              <div class="box" style="background: #e0edff"></div>
              20%>X>0%
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="right-toolbar">
          <el-form ref="form" :model="filter" :inline="true" class="section-toolbar">
            <el-form-item label="时间:" style="margin-right: 30px">
              <el-date-picker
                v-model="filter.time"
                type="daterange"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="mini"
                value-format="yyyy-MM-dd"
                style="width: 260px; height: 36px"
                @change="changeTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="金额排名前:">
              <BigPowerInput
                type="number"
                :min="1"
                v-model="filter.limit"
                size="mini"
                style="width: 80px"
              ></BigPowerInput>
            </el-form-item>
            <el-form-item label="的地区" style="margin-left: 10px"> </el-form-item>
          </el-form>
          <!-- <button class="btn-link btn-fullscreen" @click="fullScreenDialogVisible = true">
            全屏
          </button> -->
        </div>

        <BigPowerTable
          :data="generalTableData"
          class="table firstTable"
          v-loading="isLoading"
          element-loading-text="拼命加载中"
        ></BigPowerTable>
      </div>
    </div>
    <el-dialog
      :visible.sync="fullScreenDialogVisible"
      :fullscreen="true"
      :modal="false"
      :title="''"
    >
      <BigPowerTable :data="generalTableData" class="table"></BigPowerTable>
    </el-dialog>
  </div>
</template>

<script>
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import ChinaCity from "./ChinaCity";
import GenericTable from "@/pages/ResultEditor/components/InvoiceIndex/components/GenericTable";
import { getAutoMiddlePageToConfig } from "@/utils/helper";
import { percentage, round } from "@/utils/format";
import { getCompanyAreaList } from "@/api/threeYears";
import { cloneDeep } from "lodash";

export default {
  name: "ChinaCityTable",
  props: {
    type: String,
  },
  filters: {
    round,
  },
  async mounted() {
    await this.loadCompanyAreaList();
  },
  data() {
    return {
      data: null,
      areaTree: [],
      fullScreenDialogVisible: false,
      filter: {
        time: [],
        limit: 15,
      },
      firstTime: "",
      lastTime: "",
      currentProvinceName: "",
      isLoading: true,
    };
  },
  components: {
    ChinaCity,
    BigPowerTable,
  },
  methods: {
    // inputChange(v) {
    //   this.isLoading = true;
    //   if (v) {
    //     this.isLoading = false;
    //   }
    // },
    setProvince(provinceName) {
      this.currentProvinceName = provinceName;
      this.$refs.map.highlight(provinceName);
    },
    handleMapClick(provinceName) {
      this.setProvince(provinceName);
    },
    changeTime() {
      this.loadCompanyAreaList();
    },
    async loadCompanyAreaList() {
      this.isLoading = true;
      const params = {};
      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      params.programId = this.$route.params.uuid;
      const res = await getCompanyAreaList(params);
      this.data = res.data;
      if (res.code == 200) {
        this.isLoading = false;
      }
    },
  },
  computed: {
    valueData() {
      if (this.type == 1) {
        //客户
        return this.data.customerList;
      } else {
        return this.data.supplierList; // 供应商
      }
    },
    //provinceTable
    generalTableData() {
      const body = this.valueData
        .filter((v) => {
          return !(v.companyNumber == 0 && v.money == 0 && v.invoiceNumber == 0 && v.scale == 0);
        })
        .filter((v, index) => {
          let ret = index > 0;
          if (this.filter.limit && this.filter.limit > 0) {
            ret = ret && index < parseInt(this.filter.limit) + 1;
          }
          return ret;
        });
      //debugger

      const money = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["money"])).toFixed(2);

        return ret;
      }, 0);

      let scale = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["scale"]), 4);

        return ret;
      }, 0);
      scale = scale > 1 ? 1 : scale;

      const companyNumber = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["companyNumber"]));

        return ret;
      }, 0);

      const invoiceNumber = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["invoiceNumber"]));
        return ret;
      }, 0);

      let processedBody = body.map((v, index) => {
        const children = v.children
          ? v.children.map((v1) => {
              return [
                {
                  content: "",
                  align: "center",
                },
                {
                  content: v1["cityName"],
                  align: "left",
                },
                {
                  content: v1["money"],
                  align: "right",
                },
                {
                  content: percentage(v1["scale"]),
                  align: "right",
                },
                {
                  content: v1["companyNumber"],
                  align: "right",
                },
                {
                  content: v1["invoiceNumber"],
                  align: "right",
                },
              ];
            })
          : null;

        return [
          {
            content: index + 1,
            align: "left",
          },
          {
            content: v["cityName"],
            align: "left",
            children,
          },
          {
            content: v["money"],
            align: "right",
          },
          {
            content: percentage(v["scale"]),
            align: "right",
          },
          {
            content: v["companyNumber"],
            align: "right",
          },
          {
            content: v["invoiceNumber"],
            align: "right",
          },
        ];
      });
      processedBody = cloneDeep(processedBody);
      if (this.currentProvinceName) {
        const theRowIndex = processedBody.findIndex((row) => {
          return row[1].content == this.currentProvinceName;
        });
        if (theRowIndex > -1) {
          const theRow = processedBody[theRowIndex];
          processedBody.splice(theRowIndex, 1);
          theRow[1].showChildren = true;
          processedBody.unshift(theRow);
        }
      }

      return {
        body: processedBody,
        header: [
          [
            {
              content: "序号",
              align: "left",
              rowspan: 2,
              width: "center",
            },
            {
              content: "省",
              align: "center",
            },
            {
              content: "金额（万元）",
              align: "center",
            },
            {
              content: "金额占比",
              align: "center",
            },
            {
              content: "企业数量",
              align: "center",
            },
            {
              content: "发票数量",
              align: "center",
            },
          ],
          [
            {
              content: "",
              align: "center",
            },
            {
              content: this.valueData[0]["cityName"],
              align: "left",
            },
            {
              content: money,
              align: "right",
            },
            {
              content: percentage(scale),
              align: "right",
            },
            {
              content: companyNumber,
              align: "right",
            },
            {
              content: invoiceNumber,
              align: "right",
            },
          ],
        ],
      };
    },
    tips() {
      return "dasdsa";
    },
    subTables() {
      return null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper
  display flex
  width 100%
  margin-top 20px

  .map
    width 50%
    flex-grow 0

  .table
    width 583px
    height: 552px;
    flex-grow 0
    //border 1px solid #D9D9D9;
    position relative

    >>> .table.table {
      //border 0 none

    }

    >>> .bigPowerTable {
      max-height 552px
      overflow auto
    }

    .right-toolbar
      display flex
      justify-content space-between
      position absolute
      z-index 1
      top -60px
      width 100%

    .btn-fullscreen
      font-size 14px

    .btns
      display flex;
      justify-content flex-end
      position absolute;
      top -50px
      right 0

  .legend-group
    display flex
    margin-bottom 10px

  .legend
    transition all 0.1s ease-in-out
    display flex
    align-items center;
    margin-right 10px
    font-size: 12px;
    color #666

    .box
      transition all 0.1s ease-in-out
      width: 12px;
      height: 12px;
      margin-right 8px


    .symbol
      vertical-align top;
      height: 13px;
      border-radius: 3px;
      margin-right 8px

>>> .el-table
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0px;
  min-height 100%

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: 100px;

    .cell {
      color: rgba(0, 0, 0, 0.6);
      padding 0
    }

    .clickable {
      color: #2F54EB;
      cursor: pointer;
      text-decoration underline
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }

>>> .el-dialog__header
  border-bottom: 0 none;
.el-input--mini .el-input__inner {
  height: 36px;
}
>>>.section-toolbar .el-form-item__label {
  color: #1D2129;
}
//表格样式
.table {
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
>>>.el-form-item{
  margin-right: 0
}
>>>.el-input--mini .el-input__inner {
  height: 36px;
}
</style>
